export default function SendNewLinkMessage() {
  return (
    <>
      <p className="mb-3 text-xl font-semibold">Oops...</p>
      <p className="mb-14 text-sm text-gray-600">
        It looks like something went wrong resetting your password. For security concerns, the link
        we provide for resetting your password has a short validity of 10 minutes. <br />
        <br />
        If you took some time resetting your password, you might want to receive a new link to reset
        your password.
      </p>
      <button className="flex w-full justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
        Send a new link
      </button>
    </>
  );
}
