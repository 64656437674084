import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink } from '@apollo/client';
import { HttpLink } from '@apollo/client/link/http';
import { TokenAuthenticationLink } from './lib/apollo/tokenAuthenticationLink';
import { AuthProvider } from './lib/auth/AuthProvider';
import { NotificationProvider } from './lib/notifications/NotificationProvider';
import { NotificationCenter } from './lib/notifications/NotificationProvider';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
      return data;
    });
  });

  function clearLocalDataAndRedirectToSignIn() {
    // Note that this code will generate a console error though because the error will still be return to the upper layer
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');

    // Clear Apollo store
    client.clearStore();

    // Redirect user to the login page
    navigate('/login', {
      state: { from: location }
    });
  }

  const httpLinkWithErrorHandling = ApolloLink.from([
    cleanTypeName,
    new TokenAuthenticationLink({
      onRefreshTokenFailed() {
        clearLocalDataAndRedirectToSignIn();
      }
    }),
    new HttpLink({ uri: process.env.REACT_APP_API_URL })
  ]);

  const client = new ApolloClient({
    uri: process.env.REACT_APP_API_URL,
    cache: new InMemoryCache({
      possibleTypes: {},
      typePolicies: {}
    }),
    link: httpLinkWithErrorHandling
  });

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <NotificationProvider>
          <NotificationCenter />
          <Outlet />
        </NotificationProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}
