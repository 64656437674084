import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { CompleteStep, CurrentStep, UpcomingStep } from '../components/LeadStepper';
import { Campaign } from '../LeadsView';
import { DEPENDENCIES_BY_SOURCE, EnrichmentSource, enrichmentsEnumToLabel } from '../types';

export interface Lead {
  id: string;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  linkedinUrl?: string;
  websiteUrl?: string;
  youtubeUrl?: string;
}

export interface LeadEnrichmentCurrentStepProps {
  campaign: Campaign;
  onContinue: () => void;
}

interface UpdateCampaignLeadsEnrichmentSourcesResponse {
  updateCampaignLeadsEnrichmentSources: {
    id: string;
    enrichmentSources: string[];
    createdAt: string;
  };
}

interface UpdateCampaignLeadsEnrichmentSourcesVariables {
  campaignId: string;
  enrichmentSources: string[];
}

const UPDATE_CAMPAIGN_LEADS_ENRICHMENT_SOURCES = gql`
  mutation UpdateCampaignLeadsEnrichmentSources(
    $campaignId: ID!
    $enrichmentSources: [EnrichmentSource!]!
  ) {
    updateCampaignLeadsEnrichmentSources(
      campaignId: $campaignId
      enrichmentSources: $enrichmentSources
    ) {
      id
      enrichmentSources
      createdAt
    }
  }
`;

const SOURCES = {
  [EnrichmentSource.linkedin_user]: {
    name: `${enrichmentsEnumToLabel[EnrichmentSource.linkedin_user]}`,
    enabled: true
  },
  [EnrichmentSource.company_website]: {
    name: `${enrichmentsEnumToLabel[EnrichmentSource.company_website]}`,
    enabled: true
  },
  [EnrichmentSource.video]: {
    name: `${enrichmentsEnumToLabel[EnrichmentSource.video]}`,
    enabled: true
  }
};

type SourceInfo = {
  [key in EnrichmentSource]: number;
};
function getAvailableStrategies(campaign: Campaign) {
  const sourceHashMap = Object.entries(DEPENDENCIES_BY_SOURCE).reduce<SourceInfo>(
    (numberOfLeadsBySource, [sourceName, sourceDependencies]) => ({
      ...numberOfLeadsBySource,
      [sourceName]: campaign.leads?.reduce(
        (numberOfEntries, lead) =>
          numberOfEntries +
          (sourceDependencies.every((dependency) => {
            return (
              dependency in lead &&
              lead?.[dependency] !== null &&
              lead?.[dependency] !== undefined &&
              lead?.[dependency] !== ''
            );
          })
            ? 1
            : 0),
        0
      )
    }),
    {} as SourceInfo
  );

  return Object.entries(sourceHashMap).map(([sourceName, numberOfLeads]) => ({
    sourceName,
    numberOfLeads
  })) as { sourceName: keyof SourceInfo; numberOfLeads: number }[];
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function LeadEnrichmentCurrentStep({
  campaign,
  onContinue
}: LeadEnrichmentCurrentStepProps) {
  const [error, setError] = useState<string | null>(null);
  const [enrichmentSources, setEnrichmentSources] = useState<string[]>(
    campaign.enrichmentSources ?? []
  );

  const [updateCampaignLeadsEnrichmentSources] = useMutation<
    UpdateCampaignLeadsEnrichmentSourcesResponse,
    UpdateCampaignLeadsEnrichmentSourcesVariables
  >(UPDATE_CAMPAIGN_LEADS_ENRICHMENT_SOURCES);

  const onEnrichLeads = () => {
    if (enrichmentSources.length > 0) {
      updateCampaignLeadsEnrichmentSources({
        variables: {
          campaignId: campaign.id,
          enrichmentSources
        }
      });
      onContinue();
    } else {
      setError('Please select at least one enrichment method');
    }
  };

  return (
    <CurrentStep
      title="Enrich leads"
      description="Configure how we should enrich your set of leads">
      <div className="flex flex-col gap-y-5 sm:flex-row sm:gap-x-5">
        <div className="flex flex-1 flex-col divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
          <div className="flex-1 px-4 py-5 sm:px-6">
            <h1 className="text-md font-medium text-black">Enrichment sources</h1>
            <span className="text-xs text-gray-500">
              The enrichment sources are based on the information you provided in your CSV file.
            </span>
          </div>
          <div className="space-y-2 px-4 py-5 sm:p-6">
            {getAvailableStrategies(campaign).map(({ sourceName, numberOfLeads }) => (
              <div key={sourceName} className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id={sourceName}
                    aria-describedby={`${sourceName}-description`}
                    name={sourceName}
                    type="checkbox"
                    onChange={() => {
                      if (!enrichmentSources.includes(sourceName)) {
                        setEnrichmentSources([...enrichmentSources, sourceName]);
                      } else {
                        setEnrichmentSources(
                          enrichmentSources.filter(
                            (enrichmentSource) => enrichmentSource !== sourceName
                          )
                        );
                      }
                    }}
                    checked={enrichmentSources.includes(sourceName)}
                    // disabled={entry.enabled === false}
                    className="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-500"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor={sourceName}
                    className={classNames(
                      'font-normal',
                      SOURCES[sourceName].enabled ? 'text-gray-900' : 'text-gray-500'
                    )}>
                    {SOURCES[sourceName].name}{' '}
                    <span className="text-gray-400">
                      ({numberOfLeads} {numberOfLeads > 1 ? 'leads' : 'lead'})
                    </span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end">
        <p className="text-sm text-red-500">{error ?? ''}</p>
        <button
          type="button"
          onClick={onEnrichLeads}
          className="rounded-md bg-yellow-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
          {enrichmentSources.length === 0 ? 'Skip' : 'Continue'}
        </button>
      </div>
    </CurrentStep>
  );
}

export interface LeadEnrichmentCompleteStepProps {
  campaign: Campaign;
  onClick: () => unknown;
}

export function LeadEnrichmentCompleteStep({ campaign, onClick }: LeadEnrichmentCompleteStepProps) {
  const enrichmentSources = campaign.enrichmentSources ?? [];
  const leadEnrichments = enrichmentSources.filter((source) => source.startsWith('lead_')).length;
  const companyEnrichments = enrichmentSources.filter((source) =>
    source.startsWith('company_')
  ).length;
  return (
    <CompleteStep
      title="Enrich leads"
      description={
        <span className="mt-1 inline-flex items-center text-sm text-green-500">
          <span>
            {leadEnrichments !== 0
              ? `${leadEnrichments} lead enrichment${leadEnrichments > 1 ? 's' : ''} selected`
              : ''}
            <br />
            {companyEnrichments !== 0
              ? `${companyEnrichments} company enrichment${
                  companyEnrichments > 1 ? 's' : ''
                } selected`
              : ''}
          </span>
        </span>
      }
      onClick={onClick}
    />
  );
}

export function LeadEnrichmentUpcomingStep() {
  return (
    <UpcomingStep
      title="Enrich leads"
      description="Configure how we should enrich your set of leads"
    />
  );
}
