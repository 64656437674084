export enum CSVParserErrorCodes {
  invalid_file_format = 'invalid_file_format',
  invalid_file_type = 'invalid_file_type',
  too_many_rows = 'too_many_rows',
  invalid_file_content = 'invalid_file_content'
}

export class CSVParserError extends Error {
  public code: CSVParserErrorCodes;

  constructor(code: CSVParserErrorCodes, message?: string) {
    super(message);
    this.code = code;
  }
}

export class InvalidFileFormatError extends CSVParserError {
  constructor(message?: string) {
    super(CSVParserErrorCodes.invalid_file_format, message);
  }
}

export class InvalidFileTypeError extends CSVParserError {
  constructor(message?: string) {
    super(CSVParserErrorCodes.invalid_file_type, message);
  }
}

interface TooManyRowsErrorDetails {
  maxEntries: number;
}

export class TooManyRowsError extends CSVParserError {
  public details?: TooManyRowsErrorDetails;
  constructor(details?: TooManyRowsErrorDetails, message?: string) {
    super(CSVParserErrorCodes.too_many_rows, message);
    this.details = details;
  }
}

interface InvalidFileContentErrorDetails {
  row?: number;
  detailedMessage?: string;
}
export class InvalidFileContentError extends CSVParserError {
  public details?: InvalidFileContentErrorDetails;
  constructor(details?: InvalidFileContentErrorDetails, message?: string) {
    super(CSVParserErrorCodes.invalid_file_content, message);
    this.details = details;
  }
}
