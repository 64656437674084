import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
import { Logout } from './lib/auth/AuthProvider';
import RequireAuth from './lib/auth/RequireAuth';
import Error404 from './routes/errors/Error404';
import Error500 from './routes/errors/Error500';
import Home from './routes/home/Home';
import Login from './routes/login/Login';
import ConfirmEmail from './routes/ConfirmEmail';
import ResetPassword from './routes/resetPassword/ResetPasswordView';
import AppLayout from './AppLayout';
import reportWebVitals from './reportWebVitals';
import RequestPasswordResetForm from './routes/resetPassword/components/RequestPasswordResetForm';
import RequestPasswordResetFail from './routes/resetPassword/components/RequestPasswordResetFail';
import ResetPasswordForm from './routes/resetPassword/components/ResetPasswordForm';
import RequestPasswordResetEmailSent from './routes/resetPassword/components/RequestPasswordResetEmailSent';
import RequestPasswordResetSuccess from './routes/resetPassword/components/RequestPasswordResetSuccess';
import Register from './routes/register/Register';
import RegisterForm from './routes/register/RegisterForm';
import RegisterEmailSent from './routes/register/RegisterEmailSent';
import App from './App';
import CampaignsView from './routes/campaigns/CampaignsView';
import CampaignStepper from './routes/campaigns/CampaignStepper';
import CampaignsLeadsView from './routes/campaigns/leads/LeadsView';
import SequencesView from './routes/campaigns/sequences/SequencesView';
import ParametersView from './routes/campaigns/parameters/ParametersView';
import ProfileView from './routes/profile/ProfileView';

// Router Config
const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <Error500 />,
    children: [
      {
        path: '/',
        element: (
          <RequireAuth>
            <AppLayout />
          </RequireAuth>
        ),
        children: [
          {
            index: true,
            element: <Home />
          },
          {
            path: 'campaigns',
            children: [
              {
                index: true,
                element: <CampaignsView />
              },
              {
                path: ':campaignId',
                element: <CampaignStepper />,
                children: [
                  {
                    path: 'leads',
                    element: <CampaignsLeadsView />
                  },
                  {
                    path: 'sequences',
                    element: <SequencesView />
                  },
                  {
                    path: 'parameters',
                    element: <ParametersView />
                  },
                  {
                    path: 'copywriting',
                    element: <></>
                  }
                ]
              }
            ]
          },
          {
            path: 'company-settings',
            element: <Home />
          },
          {
            path: 'profile',
            element: <ProfileView />
          },
          {
            path: '/logout',
            element: <Logout />
          }
        ]
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/register',
        element: <Register />,
        children: [
          {
            index: true,
            element: <RegisterForm />
          },
          {
            path: 'email-sent',
            element: <RegisterEmailSent />
          }
        ]
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
        children: [
          {
            path: 'request',
            element: <RequestPasswordResetForm />
          },
          {
            path: 'email-sent',
            element: <RequestPasswordResetEmailSent />
          },
          {
            path: 'confirm',
            element: <ResetPasswordForm />
          },
          {
            path: 'success',
            element: <RequestPasswordResetSuccess />
          },
          {
            path: 'failed',
            element: <RequestPasswordResetFail />
          }
        ]
      },
      {
        path: 'confirm-email',
        element: <ConfirmEmail />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
]);

document.getElementById('css-root')?.classList.add('dark');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
