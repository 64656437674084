import { CheckIcon } from '@heroicons/react/20/solid';
import { PropsWithChildren, useState } from 'react';
import {
  LeadImportCompleteStep,
  LeadImportCurrentStep,
  LeadImportUpcomingStep
} from '../import/LeadImport';
import {
  LeadEnrichmentCompleteStep,
  LeadEnrichmentCurrentStep,
  LeadEnrichmentUpcomingStep
} from '../enrichment/LeadEnrichment';
import { Campaign } from '../LeadsView';
import {
  LeadResultsCompleteStep,
  LeadResultsCurrentStep,
  LeadResultsUpcomingStep
} from '../results/LeadResults';
import { useNavigate } from 'react-router-dom';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface LeadStepperProps {
  campaign: Campaign;
}

const STEPS = [
  {
    title: 'Import',
    Current: LeadImportCurrentStep,
    Complete: LeadImportCompleteStep,
    Upcoming: LeadImportUpcomingStep
  },
  {
    title: 'Enrich leads',
    Current: LeadEnrichmentCurrentStep,
    Complete: LeadEnrichmentCompleteStep,
    Upcoming: LeadEnrichmentUpcomingStep
  },
  {
    title: 'Results',
    Current: LeadResultsCurrentStep,
    Complete: LeadResultsCompleteStep,
    Upcoming: LeadResultsUpcomingStep
  }
];

export default function LeadStepper({ campaign }: LeadStepperProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const onContinue = () => {
    if (currentStep < STEPS.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      navigate(`/campaigns/${campaign.id}/sequences`);
    }
  };

  function computeStepType(stepIdx: number) {
    if (stepIdx < currentStep) return StepType.COMPLETE;
    if (stepIdx === currentStep) return StepType.CURRENT;
    return StepType.UPCOMING;
  }

  return (
    <nav aria-label="Lead import progress">
      <ol role="list" className="overflow-hidden">
        {STEPS.map((step, stepIdx) => {
          const stepType = computeStepType(stepIdx);
          return (
            <li
              key={step.title}
              className={classNames(stepIdx !== STEPS.length - 1 ? 'pb-6' : '', 'relative')}>
              {/* Display the line between steps */}
              {stepIdx !== STEPS.length - 1 && (
                <div
                  className={classNames(
                    'absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5',
                    stepType === StepType.COMPLETE ? 'bg-yellow-500' : 'bg-gray-300'
                  )}
                  aria-hidden="true"
                />
              )}

              <StepSwitch
                type={stepType}
                Current={<step.Current campaign={campaign} onContinue={onContinue} />}
                Complete={
                  <step.Complete campaign={campaign} onClick={() => setCurrentStep(stepIdx)} />
                }
                Upcoming={<step.Upcoming />}
              />
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

enum StepType {
  CURRENT,
  COMPLETE,
  UPCOMING
}
type StepSwitchProps = {
  type: StepType;
  Current: JSX.Element;
  Complete: JSX.Element;
  Upcoming: JSX.Element;
};
function StepSwitch({ type, Current, Complete, Upcoming }: StepSwitchProps) {
  switch (type) {
    case StepType.CURRENT:
      return Current;
    case StepType.COMPLETE:
      return Complete;
    case StepType.UPCOMING:
      return Upcoming;
    default:
      return null;
  }
}

type StepProps = {
  title: string | JSX.Element;
  description: string | JSX.Element;
};

type CompleteStepProps = StepProps & { onClick?: () => unknown };
type CurrentStepProps = PropsWithChildren<StepProps>;
type UpcomingStepProps = StepProps;

export const CompleteStep = ({ title, description, onClick = () => {} }: CompleteStepProps) => (
  <div className="group relative flex cursor-pointer items-start" onClick={onClick}>
    <span className="flex h-9 items-center">
      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-yellow-500 group-hover:bg-yellow-600">
        <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    </span>
    <div className="ml-4 flex min-w-0 flex-1 flex-col">
      {typeof title === 'string' ? <span className="text-sm font-medium">{title}</span> : title}
      <span className="text-sm text-gray-500">{description}</span>
    </div>
  </div>
);

export const CurrentStep = ({ title, description, children }: CurrentStepProps) => (
  <div className="group relative flex items-start" aria-current="step">
    <div className="flex h-9 items-center" aria-hidden="true">
      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-yellow-500 bg-white">
        <span className="h-2.5 w-2.5 rounded-full bg-yellow-500" />
      </span>
    </div>
    <div className="ml-4 flex min-w-0 flex-1 flex-col">
      <span className="text-sm font-medium text-yellow-500">{title}</span>
      <span className="text-sm text-gray-500">{description}</span>
      {children && <div className="mt-8">{children}</div>}
    </div>
  </div>
);

export const UpcomingStep = ({ title, description }: UpcomingStepProps) => (
  <div className="group relative flex items-start">
    <span className="flex h-9 items-center" aria-hidden="true">
      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
        <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
      </span>
    </span>
    <div className="ml-4 flex min-w-0 flex-1 flex-col">
      <span className="text-sm font-medium text-gray-500">{title}</span>
      <span className="text-sm text-gray-500">{description}</span>
    </div>
  </div>
);
