import { gql, useQuery } from '@apollo/client';
import { CheckIcon } from '@heroicons/react/24/solid';
import {
  NavLink,
  Outlet,
  generatePath,
  matchPath,
  useHref,
  useLocation,
  useParams,
  useResolvedPath
} from 'react-router-dom';

interface Step {
  id: string;
  name: string;
  description: string;
  href: string;
  status: string;
}

const steps = [
  {
    id: '01',
    name: 'Leads',
    description: 'Import your leads file.',
    href: '/campaigns/:campaignId/leads',
    status: 'complete'
  },
  {
    id: '02',
    name: 'Sequences',
    description: 'Organize the contact sequences.',
    href: '/campaigns/:campaignId/sequences',
    status: 'current'
  },
  {
    id: '03',
    name: 'Parameters',
    description: 'Define enrichment parameters.',
    href: '/campaigns/:campaignId/parameters',
    status: 'upcoming'
  },
  {
    id: '04',
    name: 'Copywriting',
    description: 'Review the results.',
    href: '/campaigns/:campaignId/copywriting',
    status: 'upcoming'
  }
];

const GET_CAMPAIGN = gql`
  query GetCampaign($campaignId: ID!) {
    campaign(campaignId: $campaignId) {
      id
      name
    }
  }
`;

interface GetCampaignVariables {
  campaignId: string;
}

interface GetCampaignResponse {
  campaign: Campaign;
}
export interface Campaign {
  id: string;
  name: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function CampaignStepper() {
  const params = useParams();
  const campaignId = params.campaignId as string;
  const { pathname } = useLocation();
  const { data, loading } = useQuery<GetCampaignResponse, GetCampaignVariables>(GET_CAMPAIGN, {
    variables: { campaignId }
  });
  const currentStepIndex = steps.findIndex((step) => matchPath(step.href, pathname) !== null);

  return (
    <div className="max-h-full overflow-auto">
      <p className="bg-gray-50 p-4 text-center text-sm font-medium">
        {loading ? 'Configuring campaign' : `Configuring ${data?.campaign.name}`}
      </p>
      <div className="sticky top-0 z-20 bg-gray-50 lg:border-b lg:border-t lg:border-gray-200">
        <nav
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          aria-label="Campaign creation progress">
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200">
            {steps.map((step, stepIdx) => {
              const stepWithInterpolatedHref = {
                ...step,
                href: generatePath(step.href, { campaignId })
              };
              return (
                <li
                  key={stepWithInterpolatedHref.id}
                  className="relative overflow-hidden lg:flex-1">
                  <div
                    className={classNames(
                      stepIdx === 0 ? 'rounded-t-md border-b-0' : '',
                      stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '',
                      'overflow-hidden border border-gray-200 lg:border-0'
                    )}>
                    {stepIdx < currentStepIndex ? (
                      <CompleteStep step={stepWithInterpolatedHref} stepIdx={stepIdx} />
                    ) : stepIdx === currentStepIndex ? (
                      <CurrentStep step={stepWithInterpolatedHref} stepIdx={stepIdx} />
                    ) : (
                      <NextStep step={stepWithInterpolatedHref} stepIdx={stepIdx} />
                    )}

                    {stepIdx !== 0 ? (
                      <>
                        {/* Separator */}
                        <div
                          className="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
                          aria-hidden="true">
                          <svg
                            className="h-full w-full text-gray-300"
                            viewBox="0 0 12 82"
                            fill="none"
                            preserveAspectRatio="none">
                            <path
                              d="M0.5 0V31L10.5 41L0.5 51V82"
                              stroke="currentcolor"
                              vectorEffect="non-scaling-stroke"
                            />
                          </svg>
                        </div>
                      </>
                    ) : null}
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
      <Outlet />
    </div>
  );
}

const CompleteStep = ({ step, stepIdx }: { step: Step; stepIdx: number }) => (
  <NavLink to={step.href} className="group">
    <span
      className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
      aria-hidden="true"
    />
    <span
      className={classNames(
        stepIdx !== 0 ? 'lg:pl-9' : '',
        'flex items-start px-6 py-5 text-sm font-medium'
      )}>
      <span className="flex-shrink-0">
        <span className="flex h-10 w-10 items-center justify-center rounded-full bg-yellow-500">
          <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </span>
      </span>
      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
        <span className="text-sm font-medium">{step.name}</span>
        <span className="text-sm font-normal text-gray-400">{step.description}</span>
      </span>
    </span>
  </NavLink>
);

const CurrentStep = ({ step, stepIdx }: { step: Step; stepIdx: number }) => (
  <NavLink to={step.href} aria-current="step">
    <span
      className="absolute left-0 top-0 h-full w-1 bg-yellow-500 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
      aria-hidden="true"
    />
    <span
      className={classNames(
        stepIdx !== 0 ? 'lg:pl-9' : '',
        'flex items-start px-6 py-5 text-sm font-medium'
      )}>
      <span className="flex-shrink-0">
        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-yellow-500">
          <span className="text-yellow-500">{step.id}</span>
        </span>
      </span>
      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
        <span className="text-sm font-medium text-yellow-500">{step.name}</span>
        <span className="text-sm font-normal text-gray-400">{step.description}</span>
      </span>
    </span>
  </NavLink>
);
const NextStep = ({ step, stepIdx }: { step: Step; stepIdx: number }) => (
  <NavLink to={step.href} className="group">
    <span
      className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
      aria-hidden="true"
    />
    <span
      className={classNames(
        stepIdx !== 0 ? 'lg:pl-9' : '',
        'flex items-start px-6 py-5 text-sm font-medium'
      )}>
      <span className="flex-shrink-0">
        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
          <span className="text-gray-500">{step.id}</span>
        </span>
      </span>
      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
        <span className="text-sm font-medium text-gray-500">{step.name}</span>
        <span className="text-sm font-normal text-gray-400">{step.description}</span>
      </span>
    </span>
  </NavLink>
);
