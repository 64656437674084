import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import BaseNotification, { BaseNotificationProps } from './BaseNotification';

type FailNotificationProps = Omit<BaseNotificationProps, 'icon'>;
export default function FailNotification({ title, content, onClose }: FailNotificationProps) {
  return (
    <BaseNotification
      title={title}
      content={content}
      onClose={onClose}
      icon={<ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
    />
  );
}
