import {
  ApolloLink,
  DefaultContext,
  FetchResult,
  NextLink,
  Observable,
  Operation,
  gql
} from '@apollo/client';
import { createOperation, ServerError } from '@apollo/client/link/utils';
import { OperationQueuing } from './queuing';

const REFRESH_ACCESS_TOKEN_MUTATION = gql`
  mutation RefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
    }
  }
`;

interface RefreshTokenResponse {
  refreshAccessToken?: {
    accessToken?: string;
  };
}

export class TokenAuthenticationLinkError extends Error {}
export class MissingRefreshToken extends TokenAuthenticationLinkError {
  constructor() {
    super('Refresh token is missing');
  }
}
export class TokenRefreshmentFailed extends TokenAuthenticationLinkError {
  constructor(err: Error) {
    super(`Token refreshment failed because: ${err}`);
  }
}

export interface Options {
  /**
   * Callback triggered when the token refreshment failed or when the refresh token is missing.
   * Allows to run additional actions like logout.
   */
  onRefreshTokenFailed?: (err: MissingRefreshToken | TokenRefreshmentFailed) => void;
}

export class TokenAuthenticationLink extends ApolloLink {
  private isRefreshingToken: Boolean;
  private queue: OperationQueuing;
  private onRefreshTokenFailed: Options['onRefreshTokenFailed'];

  constructor(options?: Options) {
    super();
    this.isRefreshingToken = false;
    this.queue = new OperationQueuing();
    this.onRefreshTokenFailed = options?.onRefreshTokenFailed;
  }

  private static assertLinkIsProperlyUsed(forward?: NextLink): asserts forward is NextLink {
    if (typeof forward !== 'function') {
      throw new Error(
        '[Refresh Token on Authentication Error Link]: This link is a non-terminating link and should not be the last in the composed chain'
      );
    }
  }

  private static setAuthHeaders(operation: Operation) {
    const token = sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');
    operation.setContext(({ headers }: DefaultContext) => ({
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : ''
      }
    }));
  }

  private refreshToken(forward: NextLink) {
    return new Observable<string>((observer) => {
      const refreshTokenOperation = createOperation(
        {},
        {
          query: REFRESH_ACCESS_TOKEN_MUTATION,
          variables: { refreshToken: localStorage.getItem('refreshToken') },
          operationName: 'RefreshAccessToken'
        }
      );

      forward(refreshTokenOperation).subscribe({
        next: ({ data }: { data: RefreshTokenResponse }) => {
          if (data?.refreshAccessToken?.accessToken)
            observer.next(data.refreshAccessToken.accessToken);
          else observer.error(new Error('Refresh token failed'));
        },
        complete: observer.complete.bind(observer),
        error: observer.error.bind(observer)
      });
    });
  }

  request(operation: Operation, forward?: NextLink): Observable<FetchResult> {
    TokenAuthenticationLink.assertLinkIsProperlyUsed(forward);
    TokenAuthenticationLink.setAuthHeaders(operation);

    // If the link is already trying to refresh the access token,
    // we queue the request and defer its execution later to send
    // the request with the new access token
    if (this.isRefreshingToken) {
      return this.queue.enqueueRequest({
        operation,
        forward
      });
    } else {
      // Otherwise we proceed we the request
      return new Observable((observer) => {
        const refreshToken = localStorage.getItem('refreshToken') ?? null;

        forward(operation).subscribe({
          next: (result) => {
            const isUnauthenticatedErrorExisting =
              result.errors &&
              result.errors.some((error) => error.extensions.code === 'UNAUTHENTICATED');

            if (!isUnauthenticatedErrorExisting) {
              // Forward the result to the upper link
              observer.next(result);
            } else if (refreshToken === null) {
              this.onRefreshTokenFailed?.(new MissingRefreshToken());
            } else {
              // There is an authentication error with the request and we have an available refresh token,
              // we'll try to refresh the access token and retry the request with this new access token

              // Remove the current token as it is now invalid
              localStorage.removeItem('accessToken');
              sessionStorage.removeItem('accessToken');

              // and queue the ongoing request that failed
              this.queue
                .enqueueRequest({
                  operation,
                  forward
                })
                .subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                });

              if (this.isRefreshingToken === false) {
                // Set the flag indicating that a refresh is ongoing
                this.isRefreshingToken = true;

                // Send request for refreshing token
                this.refreshToken(forward).subscribe(
                  (accessToken) => {
                    localStorage.setItem('accessToken', accessToken);
                    sessionStorage.setItem('accessToken', accessToken);

                    // Refresh operations headers
                    this.queue.queuedRequests.forEach((request) => {
                      TokenAuthenticationLink.setAuthHeaders(request.operation);
                    });

                    // Now that we have a new access token, we can execute all queued requests
                    this.queue.consumeQueue();
                  },
                  (err) => {
                    this.onRefreshTokenFailed?.(new TokenRefreshmentFailed(err));
                  },
                  () => {
                    this.isRefreshingToken = false;
                  }
                );
              }
            }
          },
          error: (err) => {
            if (err.name === 'ServerError' && (err as ServerError).statusCode === 401) {
              //ignore error
            } else observer.error(err);
          },
          complete: (...args) => {
            console.log(args);
            return observer.complete.bind(observer)(...args);
          }
        });
      });
    }
  }
}
