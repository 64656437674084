export enum ResourceType {
  PRODUCT_DEMO = 'PRODUCT_DEMO',
  ARTICLE = 'ARTICLE',
  DOCUMENT = 'DOCUMENT',
  COMPUTER_GRAPHICS = 'COMPUTER_GRAPHICS',
  VIDEO = 'VIDEO',
  CUSTOM_VIDEO = 'CUSTOM_VIDEO',
  CASE_STUDY = 'CASE_STUDY',
  GUIDE_WHITE_PAPER = 'GUIDE_WHITE_PAPER'
}

export const resourceTypeToLabel = {
  [ResourceType.PRODUCT_DEMO]: 'Product demo',
  [ResourceType.ARTICLE]: 'Article',
  [ResourceType.DOCUMENT]: 'Document',
  [ResourceType.COMPUTER_GRAPHICS]: 'Computer graphics',
  [ResourceType.VIDEO]: 'Video',
  [ResourceType.CUSTOM_VIDEO]: 'Custom video',
  [ResourceType.CASE_STUDY]: 'Case study',
  [ResourceType.GUIDE_WHITE_PAPER]: 'Guide / White paper'
};

export enum LanguageTone {
  FORMAL = 'FORMAL',
  INFORMAL = 'INFORMAL',
  CONVINCING = 'CONVINCING',
  DESCRIPTIVE = 'DESCRIPTIVE',
  NARRATIVE = 'NARRATIVE',
  POETIC = 'POETIC',
  TECHNICAL = 'TECHNICAL',
  PERSUASIVE = 'PERSUASIVE',
  SATIRICAL = 'SATIRICAL',
  EMPATHETIC = 'EMPATHETIC',
  EDUCATIONAL = 'EDUCATIONAL',
  EMOTIONAL = 'EMOTIONAL',
  HUMOROUS = 'HUMOROUS',
  JOURNALISTIC = 'JOURNALISTIC',
  PHILOSOPHICAL = 'PHILOSOPHICAL',
  CREATIVE = 'CREATIVE',
  DIDACTIC = 'DIDACTIC',
  THOUGHTFUL = 'THOUGHTFUL',
  INSPIRING = 'INSPIRING',
  ASSERTIVE = 'ASSERTIVE',
  IMPACTFUL = 'IMPACTFUL',
  MYSTERIOUS = 'MYSTERIOUS',
  ARGUMENTATIVE = 'ARGUMENTATIVE',
  FANTASTIC = 'FANTASTIC',
  DRAMATIC = 'DRAMATIC',
  IRONIC = 'IRONIC',
  INSTRUCTIVE = 'INSTRUCTIVE',
  ENGAGED = 'ENGAGED',
  EPIC = 'EPIC',
  LYRICAL = 'LYRICAL',
  REALISTIC = 'REALISTIC'
}

export const languageToneToLabel = {
  [LanguageTone.FORMAL]: 'Formal',
  [LanguageTone.INFORMAL]: 'Informal',
  [LanguageTone.CONVINCING]: 'Convincing',
  [LanguageTone.DESCRIPTIVE]: 'Descriptive',
  [LanguageTone.NARRATIVE]: 'Narrative',
  [LanguageTone.POETIC]: 'Poetic',
  [LanguageTone.TECHNICAL]: 'Technical',
  [LanguageTone.PERSUASIVE]: 'Persuasive',
  [LanguageTone.SATIRICAL]: 'Satirical',
  [LanguageTone.EMPATHETIC]: 'Empathetic',
  [LanguageTone.EDUCATIONAL]: 'Educational',
  [LanguageTone.EMOTIONAL]: 'Emotional',
  [LanguageTone.HUMOROUS]: 'Humorous',
  [LanguageTone.JOURNALISTIC]: 'Journalistic',
  [LanguageTone.PHILOSOPHICAL]: 'Philosophical',
  [LanguageTone.CREATIVE]: 'Creative',
  [LanguageTone.DIDACTIC]: 'Didactic',
  [LanguageTone.THOUGHTFUL]: 'Thoughtful',
  [LanguageTone.INSPIRING]: 'Inspiring',
  [LanguageTone.ASSERTIVE]: 'Assertive',
  [LanguageTone.IMPACTFUL]: 'Impactful',
  [LanguageTone.MYSTERIOUS]: 'Mysterious',
  [LanguageTone.ARGUMENTATIVE]: 'Argumentative',
  [LanguageTone.FANTASTIC]: 'Fantastic',
  [LanguageTone.DRAMATIC]: 'Dramatic',
  [LanguageTone.IRONIC]: 'Ironic',
  [LanguageTone.INSTRUCTIVE]: 'Instructive',
  [LanguageTone.ENGAGED]: 'Engaged',
  [LanguageTone.EPIC]: 'Epic',
  [LanguageTone.LYRICAL]: 'Lyrical',
  [LanguageTone.REALISTIC]: 'Realistic'
};

export enum Language {
  FR_VOUS = 'FR_VOUS',
  FR_TU = 'FR_TU',
  EN = 'EN'
}

export const languageToLabel = {
  [Language.FR_VOUS]: 'French (vous)',
  [Language.FR_TU]: 'French (tu)',
  [Language.EN]: 'Engilsh'
};
