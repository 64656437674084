import { gql, useMutation, useQuery } from '@apollo/client';
import Loader from '../../../designSystem/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import ParametersForm, { FormValues } from './ParametersForm';
import { Language, LanguageTone, ResourceType } from './types';
import { FormikHelpers } from 'formik';
import { useAuth } from '../../../lib/auth/AuthProvider';
import { useCurrentProfile } from '../../../components/CurrentProfileProvider';

const GET_CAMPAIGN = gql`
  query GetCampaign($campaignId: ID!) {
    campaign(campaignId: $campaignId) {
      id
      name
      profile {
        id
      }
      leads {
        id
        firstName
        lastName
      }
      languageOptions {
        language
        languageTone
      }
      createdAt
    }
  }
`;

export interface Campaign {
  id: string;
  name: string;
  profile: {
    id: string;
  };
  leads: Lead[];
  languageOptions: {
    language: Language;
    languageTone: LanguageTone;
  };
  createdAt: string;
}

export interface Lead {
  id: string;
  firstName: string;
  lastName: string;
}

interface GetCampaignResponse {
  campaign: Campaign;
}

const GET_PROFILE = gql`
  query GetProfile($profileId: String!) {
    profile(id: $profileId) {
      id
      campaignConfigurations {
        id
        name
        companyName
        audience
        problems
        stakes
        resources {
          type
          url
        }
        valuePropositions
        references
      }
    }
  }
`;

interface GetProfileVariables {
  profileId: string;
}

interface GetProfileResponse {
  profile: Profile;
}

interface Profile {
  id: string;
  campaignConfigurations: {
    id: string | null;
    name: string | null;
    companyName: string | null;
    audience: string | null;
    problems: string[] | null;
    stakes: string[] | null;
    resources: {
      type: string;
      url: string;
    }[];
    valuePropositions: string[] | null;
    references: string[] | null;
  }[];
}

interface GetCampaignResponse {
  campaign: Campaign;
}

const SET_CAMPAIGN_LANGUAGE_OPTIONS = gql`
  mutation ($campaignId: ID!, $languageOptions: LanguageOptionsInput!) {
    setCampaignLanguageOptions(campaignId: $campaignId, languageOptions: $languageOptions) {
      id
      languageOptions {
        language
        languageTone
      }
    }
  }
`;

interface SetCampaignLanguageOptionsVariables {
  campaignId: string;
  languageOptions: {
    language: Language;
    languageTone: LanguageTone;
  };
}

interface SetCampaignLanguageOptionsResponse {
  id: string;
  languageOptions: {
    language: Language;
    languageTone: LanguageTone;
  };
}

const SET_CAMPAIGN_CONFIGURATION = gql`
  mutation SetCampaignConfiguration($profileId: ID!, $configuration: CampaignConfigurationInput!) {
    setCampaignConfiguration(profileId: $profileId, configuration: $configuration) {
      id
      campaignConfigurations {
        id
        name
        audience
        problems
        stakes
        resources {
          type
          url
        }
        valuePropositions
        references
      }
    }
  }
`;

interface SetCampaignConfigurationVariables {
  profileId: string;
  configuration: {
    audience?: string | null;
    problems?: string[] | null;
    stakes?: string[] | null;
    resources:
      | {
          type: ResourceType;
          url: string;
        }[]
      | null;
    valuePropositions: string[] | null;
    references: string[] | null;
  };
}

interface SetCampaignConfigurationResponse {
  id: string;
  campaingConfigurations: {
    id: string;
    name: string;
    audience?: string[] | null;
    problems?: string[] | null;
    stakes?: string[] | null;
    resources:
      | {
          type: ResourceType;
          url: string;
        }[]
      | null;
    valuePropositions: string[] | null;
    references: string[] | null;
  };
}

export default function ParametersView() {
  const { currentProfile } = useCurrentProfile();
  const currentProfileId = currentProfile?.id as string;
  const navigate = useNavigate();
  const params = useParams();
  const campaignId = params.campaignId as string;
  const { data: campaignData, loading: isLoadingCampaign } = useQuery<GetCampaignResponse>(
    GET_CAMPAIGN,
    {
      variables: { campaignId }
    }
  );
  const { data: profileData, loading: isLoadingProfile } = useQuery<
    GetProfileResponse,
    GetProfileVariables
  >(GET_PROFILE, {
    variables: { profileId: currentProfileId }
  });

  const [setCampaignConfiguration] = useMutation<
    SetCampaignConfigurationResponse,
    SetCampaignConfigurationVariables
  >(SET_CAMPAIGN_CONFIGURATION);
  const [setCampaignLanguageOptions] = useMutation<
    SetCampaignLanguageOptionsResponse,
    SetCampaignLanguageOptionsVariables
  >(SET_CAMPAIGN_LANGUAGE_OPTIONS);

  const campaign = campaignData?.campaign as Campaign;
  const profile = profileData?.profile as Profile;

  const onSubmit = (
    values: FormValues,
    { setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    const { language, languageTone, ...configuration } = values;
    setCampaignConfiguration({
      variables: {
        profileId: currentProfileId,
        configuration
      }
    });
    setCampaignLanguageOptions({
      variables: {
        campaignId,
        languageOptions: { language, languageTone }
      }
    });
  };

  return (
    <div className="mx-auto max-w-2xl px-4 py-10 sm:px-6">
      {isLoadingCampaign || isLoadingProfile ? (
        <Loader />
      ) : (
        <ParametersForm campaign={campaign} profile={profile} onSubmit={onSubmit} />
      )}
    </div>
  );
}
