import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export enum AlertType {
  warning = 'warning'
}
interface AlertProps {
  className: string;
  type: AlertType;
  title?: string;
  content?: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
export default function Alert({ type, title, content, className }: AlertProps) {
  return (
    <div className={classNames('rounded-md bg-yellow-50 p-4', className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">{title}</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{content}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
