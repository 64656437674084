import * as yup from 'yup';
import { Formik, Field, Form, FieldProps, FieldArray, FormikHelpers } from 'formik';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ChangeEvent } from 'react';
import {
  Language,
  LanguageTone,
  ResourceType,
  languageToLabel,
  languageToneToLabel,
  resourceTypeToLabel
} from './types';
import { profile } from 'console';

export interface FormValues {
  companyName: string;
  audience: string;
  problems: string[];
  stakes: string[];
  resources: {
    url: string;
    type: ResourceType;
  }[];
  valuePropositions: string[];
  references: string[];
  languageTone: LanguageTone;
  language: Language;
}

const PROBLEMS_PLACEHOLDERS = [
  'My email response rate is low',
  "I take a full afternoon for writing my campaigns' emails"
];

const STAKES_PLACEHOLDERS = [
  'Increase their number of qualified meetings',
  'Get their variable compensation at the end of month'
];

const VALUE_PROPOSITION_PLACEHOLDERS = [
  'Double the reponse rate',
  'Divide by 10 time to write cold emails'
];

const REFERENCES_PLACEHOLDERS = ['Qonto', 'Doctolib'];

const parametersSchema = yup.object({
  companyName: yup.string().required('Please enter your company name'),
  audience: yup.string().required('Please enter the audience'),
  problems: yup
    .array()
    .of(yup.string().required('Please enter a problem'))
    .min(1, 'Please provide at list 1 problem your company is solving'),
  stakes: yup
    .array()
    .of(yup.string().required('Please enter a stake'))
    .min(1, 'Please provide at list 1 stake of the client you target'),
  resources: yup.array().of(
    yup.object({
      url: yup
        .string()
        .url('Please provide a valid url')
        .required('Please enter a link for this resource'),
      type: yup.string().oneOf(Object.keys(resourceTypeToLabel).map((key) => key))
    })
  ),
  valuePropositions: yup
    .array()
    .of(yup.string().required('Please enter a value proposition'))
    .min(1, 'Please provide at list 1 benefit of your solution'),
  references: yup.array().of(yup.string().required()),
  languageTone: yup.string().required('Please provide the language tone'),
  language: yup.string().required('Please provide the language')
});

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const INITIAL_VALUES: FormValues = {
  companyName: '',
  audience: '',
  problems: [''],
  stakes: [''],
  resources: [],
  valuePropositions: [''],
  references: [''],
  languageTone: LanguageTone.FORMAL,
  language: Language.FR_VOUS
};

interface ParametersFormProps {
  campaign: {
    leads: { id: string }[];
    languageOptions: {
      language: Language;
      languageTone: LanguageTone;
    };
  };
  profile: {
    id: string;
    campaignConfigurations: {
      id: string | null;
      name: string | null;
      companyName: string | null;
      audience: string | null;
      problems: string[] | null;
      stakes: string[] | null;
      resources: {
        type: string;
        url: string;
      }[];
      valuePropositions: string[] | null;
      references: string[] | null;
    }[];
  };
  onSubmit: (values: FormValues, formHelpers: FormikHelpers<FormValues>) => void | Promise<void>;
}

export default function ParametersForm({ campaign, profile, onSubmit }: ParametersFormProps) {
  const withMaxWordsLimit =
    (handler: React.ChangeEventHandler<HTMLInputElement>, maxWords = 15) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      // Only call handler if maxWords is not reached
      if (value.split(' ').length <= maxWords) {
        handler(event);
      }
    };

  const initialValues = Object.entries(INITIAL_VALUES).reduce(
    (acc, [key, defaultValue]) => ({
      ...acc,
      [key]:
        campaign?.languageOptions?.[key as keyof typeof campaign.languageOptions] ??
        profile?.campaignConfigurations?.[0][
          key as keyof (typeof profile.campaignConfigurations)[number]
        ] ??
        defaultValue
    }),
    {}
  ) as FormValues;

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={parametersSchema}
      onSubmit={onSubmit}>
      {({ isValid, isSubmitting, status, values }) => (
        <Form>
          {status?.formError && (
            <p className="text-center text-sm text-red-600">{status?.formError}</p>
          )}
          <div className="mx-auto grid grid-cols-1 gap-x-6 gap-y-8 border-b border-gray-900/10 pb-12 sm:grid-cols-6">
            <div className="col-span-full">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Company context</h2>

              <p className="text-sm leading-6 text-gray-600">
                This information is related to the profile you are currently using.
              </p>
            </div>

            <Field name="companyName">
              {({ field, meta }: FieldProps) => (
                <div className="sm:col-span-full">
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Company Name
                  </label>
                  <div className="mt-2">
                    <input
                      id="companyName"
                      type="text"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      required
                      aria-invalid={meta.touched && !!meta.error}
                      className={classNames(
                        meta.touched && meta.error
                          ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                          : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                        'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                      )}
                    />
                  </div>
                  {meta.touched && !!meta.error && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {meta.error}
                    </p>
                  )}
                </div>
              )}
            </Field>

            <Field name="audience">
              {({ field, meta }: FieldProps) => (
                <div className="sm:col-span-full">
                  <label
                    htmlFor="audience"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Audience
                  </label>
                  <p className="text-xs text-gray-400" id="email-description">
                    Add your personae and the type of company you want to reach out
                  </p>
                  <div className="mt-2">
                    <input
                      id="audience"
                      type="text"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      required
                      placeholder="ex: SDR people in a B2B SaaS company"
                      aria-invalid={meta.touched && !!meta.error}
                      className={classNames(
                        meta.touched && meta.error
                          ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                          : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                        'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                      )}
                    />
                  </div>
                  {meta.touched && !!meta.error && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {meta.error}
                    </p>
                  )}
                </div>
              )}
            </Field>

            <div className="col-span-full">
              <label
                htmlFor={`problems[${values.problems.length - 1}]`}
                className="block text-sm font-medium leading-6 text-gray-900">
                Problems
              </label>
              <p className="text-xs text-gray-400" id="email-description">
                Add problems you want to discuss in your message(s) (15 words max)
              </p>
              <FieldArray
                name="problems"
                render={(arrayHelpers: any) => (
                  <div className="mt-2 space-y-2">
                    {values.problems.map((problem, index) => {
                      const isLastElement = index === values.problems.length - 1;
                      return (
                        <div key={index}>
                          <Field name={`problems.${index}`}>
                            {({ field, meta }: FieldProps) => (
                              <>
                                <div className="flex items-center space-x-2 sm:space-x-4">
                                  <input
                                    id={`problems[${index}]`}
                                    type="text"
                                    name={field.name}
                                    value={field.value}
                                    onChange={withMaxWordsLimit(field.onChange, 15)}
                                    onBlur={field.onBlur}
                                    placeholder={`ex: ${
                                      PROBLEMS_PLACEHOLDERS[index % PROBLEMS_PLACEHOLDERS.length]
                                    }`}
                                    required
                                    aria-invalid={meta.touched && !!meta.error}
                                    className={classNames(
                                      meta.touched && meta.error
                                        ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                                        : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                                      'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                                    )}
                                  />
                                  {values.problems.length > 1 && index !== 0 && (
                                    <button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      className="rounded-full bg-yellow-500 p-1 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                  )}
                                </div>
                                {meta.touched && !!meta.error && (
                                  <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      onClick={() => arrayHelpers.insert(values.problems.length, '')}
                      className="inline-flex items-center gap-x-1 rounded-md border-[1px] border-yellow-500 px-2.5 py-1.5 text-xs font-medium text-white text-yellow-500 shadow-sm hover:bg-yellow-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                      <PlusIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                      Add problem
                    </button>
                  </div>
                )}
              />
            </div>

            <div className="col-span-full">
              <label
                htmlFor={`stakes[${values.stakes.length - 1}]`}
                className="block text-sm font-medium leading-6 text-gray-900">
                Stakes
              </label>
              <p className="text-xs text-gray-400" id="email-description">
                Add your prospects' stakes you want to discuss in your message(s) (15 words max)
              </p>
              <FieldArray
                name="stakes"
                render={(arrayHelpers: any) => (
                  <div className="mt-2 space-y-2">
                    {values.stakes.map((stake, index) => {
                      const isLastElement = index === values.stakes.length - 1;
                      return (
                        <div key={index}>
                          <Field name={`stakes.${index}`}>
                            {({ field, meta }: FieldProps) => (
                              <>
                                <div className="flex items-center space-x-2 sm:space-x-4">
                                  <input
                                    id={`stakes[${index}]`}
                                    type="text"
                                    name={field.name}
                                    value={field.value}
                                    onChange={withMaxWordsLimit(field.onChange, 15)}
                                    onBlur={field.onBlur}
                                    placeholder={`ex: ${
                                      STAKES_PLACEHOLDERS[index % STAKES_PLACEHOLDERS.length]
                                    }`}
                                    required
                                    aria-invalid={meta.touched && !!meta.error}
                                    className={classNames(
                                      meta.touched && meta.error
                                        ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                                        : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                                      'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                                    )}
                                  />
                                  {values.stakes.length > 1 && index !== 0 && (
                                    <button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      className="rounded-full bg-yellow-500 p-1 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                  )}
                                </div>
                                {meta.touched && !!meta.error && (
                                  <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      onClick={() => arrayHelpers.insert(values.stakes.length, '')}
                      className="inline-flex items-center gap-x-1 rounded-md border-[1px] border-yellow-500 px-2.5 py-1.5 text-xs font-medium text-white text-yellow-500 shadow-sm hover:bg-yellow-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                      <PlusIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                      Add stake
                    </button>
                  </div>
                )}
              />
            </div>

            <div className="col-span-full">
              <label
                htmlFor={`resources[${values.resources.length - 1}].url`}
                className="block text-sm font-medium leading-6 text-gray-900">
                Resources
              </label>
              <p className="text-xs text-gray-400" id="email-description">
                Add resource(s) you want to share in your message(s)
              </p>
              <FieldArray
                name="resources"
                render={(arrayHelpers: any) => (
                  <div className="mt-2 space-y-2">
                    {values.resources.map((resource, index) => {
                      const isLastElement = index === values.references.length - 1;
                      return (
                        <div key={index}>
                          <div className="flex items-start space-x-2 sm:space-x-4">
                            <Field name={`resources[${index}].url`}>
                              {({ field, meta }: FieldProps) => (
                                <div className="flex flex-1 flex-col">
                                  <input
                                    id={field.name}
                                    type="text"
                                    name={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    placeholder="ex: https://www.youtube.com/watch?v=<...>"
                                    required
                                    aria-invalid={meta.touched && !!meta.error}
                                    className={classNames(
                                      meta.touched && meta.error
                                        ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                                        : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                                      'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                                    )}
                                  />
                                  {meta.touched && !!meta.error && (
                                    <p className="mt-2 text-sm text-red-600" id="email-error">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                            <Field name={`resources[${index}].type`}>
                              {({ field, meta }: FieldProps) => (
                                <div className="flex flex-col">
                                  <select
                                    id={field.name}
                                    name={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-yellow-500 sm:text-sm sm:leading-6">
                                    {Object.entries(resourceTypeToLabel).map(([value, label]) => (
                                      <option key={value} value={value}>
                                        {label}
                                      </option>
                                    ))}
                                  </select>

                                  {meta.touched && !!meta.error && (
                                    <p className="mt-2 text-sm text-red-600" id="email-error">
                                      {meta.error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>

                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              className="mt-1 rounded-full bg-yellow-500 p-1 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                              <MinusIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      onClick={() =>
                        arrayHelpers.insert(values.resources.length, {
                          url: '',
                          type: ResourceType.VIDEO
                        })
                      }
                      className="inline-flex items-center gap-x-1 rounded-md border-[1px] border-yellow-500 px-2.5 py-1.5 text-xs font-medium text-white text-yellow-500 shadow-sm hover:bg-yellow-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                      <PlusIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                      Add resource
                    </button>
                  </div>
                )}
              />
            </div>

            <div className="col-span-full">
              <label
                htmlFor={`valuePropositions[${values.valuePropositions.length - 1}]`}
                className="block text-sm font-medium leading-6 text-gray-900">
                Value Propositions
              </label>
              <p className="text-xs text-gray-400" id="email-description">
                Add your product's value proposition(s) you want to discuss in your message(s) (15
                words max)
              </p>
              <FieldArray
                name="valuePropositions"
                render={(arrayHelpers: any) => (
                  <div className="mt-2 space-y-2">
                    {values.valuePropositions.map((valueProposition, index) => {
                      const isLastElement = index === values.valuePropositions.length - 1;
                      return (
                        <div key={index}>
                          <Field name={`valuePropositions.${index}`}>
                            {({ field, meta }: FieldProps) => (
                              <>
                                <div className="flex items-center space-x-2 sm:space-x-4">
                                  <input
                                    id={`valuePropositions[${index}]`}
                                    type="text"
                                    name={field.name}
                                    value={field.value}
                                    onChange={withMaxWordsLimit(field.onChange, 15)}
                                    onBlur={field.onBlur}
                                    placeholder={`ex: ${
                                      VALUE_PROPOSITION_PLACEHOLDERS[
                                        index % VALUE_PROPOSITION_PLACEHOLDERS.length
                                      ]
                                    }`}
                                    required
                                    aria-invalid={meta.touched && !!meta.error}
                                    className={classNames(
                                      meta.touched && meta.error
                                        ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                                        : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                                      'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                                    )}
                                  />
                                  {values.valuePropositions.length > 1 && index !== 0 && (
                                    <button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      className="rounded-full bg-yellow-500 p-1 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                  )}
                                </div>
                                {meta.touched && !!meta.error && (
                                  <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      onClick={() => arrayHelpers.insert(values.valuePropositions.length, '')}
                      className="inline-flex items-center gap-x-1 rounded-md border-[1px] border-yellow-500 px-2.5 py-1.5 text-xs font-medium text-white text-yellow-500 shadow-sm hover:bg-yellow-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                      <PlusIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                      Add value proposition
                    </button>
                  </div>
                )}
              />
            </div>

            <div className="col-span-full">
              <label
                htmlFor={`references[${values.references.length - 1}]`}
                className="block text-sm font-medium leading-6 text-gray-900">
                References
              </label>
              <p className="text-xs text-gray-400" id="email-description">
                Add your clients references you want to mention in your message(s) (15 words max)
              </p>
              <FieldArray
                name="references"
                render={(arrayHelpers: any) => (
                  <div className="mt-2 space-y-2">
                    {values.references.map((reference, index) => {
                      const isLastElement = index === values.references.length - 1;
                      return (
                        <div key={index}>
                          <Field name={`references.${index}`}>
                            {({ field, meta }: FieldProps) => (
                              <>
                                <div className="flex items-center space-x-2 sm:space-x-4">
                                  <input
                                    id={`references[${index}]`}
                                    type="text"
                                    name={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    placeholder={`ex: ${
                                      REFERENCES_PLACEHOLDERS[
                                        index % REFERENCES_PLACEHOLDERS.length
                                      ]
                                    }`}
                                    required
                                    aria-invalid={meta.touched && !!meta.error}
                                    className={classNames(
                                      meta.touched && meta.error
                                        ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                                        : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                                      'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                                    )}
                                  />
                                  {values.references.length > 1 && index !== 0 && (
                                    <button
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                      className="rounded-full bg-yellow-500 p-1 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                  )}
                                </div>
                                {meta.touched && !!meta.error && (
                                  <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {meta.error}
                                  </p>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      onClick={() => arrayHelpers.insert(values.references.length, '')}
                      className="inline-flex items-center gap-x-1 rounded-md border-[1px] border-yellow-500 px-2.5 py-1.5 text-xs font-medium text-white text-yellow-500 shadow-sm hover:bg-yellow-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                      <PlusIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                      Add reference
                    </button>
                  </div>
                )}
              />
            </div>
          </div>

          <div className="mx-auto grid grid-cols-1 gap-x-6 gap-y-8 py-12 sm:grid-cols-6">
            <div className="col-span-full ">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Campaign context</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                This information is realted to the campaign you are currently creating.
              </p>
            </div>

            <Field name="languageTone">
              {({ field, meta }: FieldProps) => (
                <div className="sm:col-span-full">
                  <label
                    htmlFor="languageTone"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Select the tone you want to use
                  </label>
                  <div className="mt-2">
                    <select
                      id={field.name}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      defaultValue={ResourceType.VIDEO}
                      className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-yellow-500 sm:text-sm sm:leading-6">
                      {Object.entries(languageToneToLabel).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {meta.touched && !!meta.error && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {meta.error}
                    </p>
                  )}
                </div>
              )}
            </Field>

            <Field name="language">
              {({ field, meta }: FieldProps) => (
                <div className="sm:col-span-full">
                  <label
                    htmlFor="language"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Select the language for generating the sequence
                  </label>
                  <div className="mt-2">
                    <select
                      id={field.name}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      defaultValue={ResourceType.VIDEO}
                      className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-yellow-500 sm:text-sm sm:leading-6">
                      {Object.entries(languageToLabel).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {meta.touched && !!meta.error && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {meta.error}
                    </p>
                  )}
                </div>
              )}
            </Field>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="inline-flex items-center gap-x-1 rounded-md bg-yellow-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
              Generate {campaign.leads.length} {`sequence${campaign.leads.length > 1 ? 's' : ''}`}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
