import { Navigate } from 'react-router-dom';
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { ReactElement, createContext, useContext, useState } from 'react';

interface Profile {
  id: string;
  name: string;
}

interface ICurrentProfileContext {
  currentProfile: Profile | null;
  setCurrentProfile: (newProfile: Profile | null) => void;
}

const CurrentProfileContext = createContext<ICurrentProfileContext | null>(null);

export const useCurrentProfile = () => {
  const context = useContext(CurrentProfileContext);

  if (context === null) {
    throw new Error(
      'It looks like you are trying to use useCurrentProfile service without initialization. Did you forget to add <CurrentProfileProvider> in your application tree ?'
    );
  }

  return context;
};

interface CurrentProfileProviderProps {
  children: ReactElement | ReactElement[];
}

interface GetUserProfilesResponse {
  me: {
    permissions: {
      profile: {
        id: string;
        name: string;
      };
      scopes: string[];
    }[];
  };
}

const GET_USER_PROFILES = gql`
  query GetUser {
    me {
      permissions(permissionsType: [PROFILE]) {
        ... on UserProfilePermission {
          profile {
            id
            name
          }
        }
        scopes
      }
    }
  }
`;

export function CurrentProfileProvider({ children }: CurrentProfileProviderProps) {
  const [currentProfile, setCurrentProfile] = useState<Profile | null>(null);
  const { data, loading } = useQuery<GetUserProfilesResponse>(GET_USER_PROFILES, {
    onCompleted(data) {
      if (localStorage.getItem('current_profile')) {
        const currentProfileData =
          data.me.permissions.find(
            (permission) => permission.profile.id === localStorage.getItem('current_profile')
          )?.profile ?? null;
        if (currentProfileData === null) localStorage.removeItem('current_profile');
        setCurrentProfile(currentProfileData);
      } else {
        setCurrentProfile(data.me.permissions?.[0].profile ?? null);
      }
    }
  });

  const updateCurrentProfile = (newProfile: Profile | null) => {
    setCurrentProfile(newProfile);
    if (newProfile === null) localStorage.removeItem('current_profile');
    else localStorage.setItem('current_profile', newProfile.id);
  };

  return (
    <CurrentProfileContext.Provider
      value={{ currentProfile, setCurrentProfile: updateCurrentProfile }}>
      {children}
    </CurrentProfileContext.Provider>
  );
}
