import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import CampaignForm, { CampaignFormProps } from './CampaignForm';

interface CampaignEmptyStateProps {
  onCreateCampaign: CampaignFormProps['onCreateCampaign'];
  isSubmitting: boolean
}

export default function CampaignEmptyState({ onCreateCampaign, isSubmitting }: CampaignEmptyStateProps) {
  return (
    <div className="max-h-lg mx-auto mt-28 max-w-lg">
      <div>
        <div className="text-center">
          <EnvelopeIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">
            Create a campaign
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Well, it looks quite empty here ! Launch your first campaign 👇
          </p>
        </div>
        <CampaignForm onCreateCampaign={onCreateCampaign} isSubmitting={isSubmitting}/>
      </div>
    </div>
  );
}
