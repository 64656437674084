import SniprLogo from '../../assets/logo_color.png';
import { gql, useQuery } from '@apollo/client';
import { Outlet, useNavigate } from 'react-router-dom';

interface GetUserResponse {
  me: {
    id: string;
  };
}

const GET_USER = gql`
  query GetUser {
    me {
      id
    }
  }
`;

export default function Register() {
  const navigate = useNavigate();
  useQuery<GetUserResponse>(GET_USER, {
    onCompleted(data) {
      if (data?.me?.id != null) {
        navigate('/');
      }
    }
  });

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-10 w-auto" src={SniprLogo} alt="Snipr" />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Register your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[640px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
