import { Fragment, useReducer, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  AdjustmentsVerticalIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import SniprLogo from './assets/logo_white.png';
import { NavLink, Outlet } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import ProfileSelection from './components/ProfileSelection';
import { NotificationCenter } from './lib/notifications/NotificationProvider';
import { idText } from 'typescript';
import { CurrentProfileProvider } from './components/CurrentProfileProvider';

interface GetUserResponse {
  me: {
    id: string;
    name: string;
    picture: string;
    givenName: string;
    familyName: string;
    locale: string;
    permissions: {
      profile: {
        id: string;
        name: string;
      };
      scopes: string[];
    }[];
  };
}

const GET_USER = gql`
  query GetUser {
    me {
      id
      name
      givenName
      familyName
      picture
      locale
      permissions(permissionsType: [PROFILE]) {
        ... on UserProfilePermission {
          profile {
            id
            name
          }
        }
        scopes
      }
    }
  }
`;

const navigation = [
  { name: 'Campagnes', href: '/campaigns', icon: ChatBubbleOvalLeftEllipsisIcon }
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function AppLayout() {
  const { loading: isUserLoading, data } = useQuery<GetUserResponse>(GET_USER, {});
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // const darkMode = localStorage.getItem('theme') === 'dark' ? true: false
  const darkMode = true;
  const user = data?.me;

  return (
    <CurrentProfileProvider>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-yellow-600 px-6 pb-2 dark:bg-gray-900">
                  <div className="flex h-16 shrink-0 items-center">
                    <img className="h-8 w-auto" src={SniprLogo} alt="Snipr" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">
                              Current profile
                            </div>
                            {!isUserLoading && (
                              <ProfileSelection
                                profiles={(user?.permissions ?? []).map(({ profile }) => profile)}
                              />
                            )}
                          </li>
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                to={item.href}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive
                                      ? 'text-white dark:bg-gray-800'
                                      : 'text-gray-400 hover:text-white dark:hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                                  )
                                }>
                                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                {item.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-200 px-6 dark:bg-gray-900">
          <div className="flex h-16 shrink-0 items-center">
            <img className="h-8 w-auto" src={SniprLogo} alt="Snipr" />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className=" -mx-2 flex flex-1 flex-col gap-y-7">
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">Current profile</div>
                {!isUserLoading && (
                  <ProfileSelection
                    profiles={(user?.permissions ?? []).map(({ profile }) => profile)}
                  />
                )}
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">Workspace</div>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <NavLink
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? 'text-white dark:bg-gray-800'
                              : 'text-gray-400 hover:text-white dark:hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )
                        }>
                        <>
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          {item.name}
                        </>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="-mx-6 mt-auto">
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? 'text-white dark:bg-gray-800'
                        : 'text-gray-400 hover:text-white dark:hover:bg-gray-800',
                      'flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6'
                    )
                  }>
                  {user?.picture ? (
                    <img
                      className="h-8 w-8 rounded-full bg-gray-500"
                      src={user.picture}
                      alt="Your profile picture"
                    />
                  ) : (
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                      <span className="text-xs font-medium leading-none text-white">
                        {user?.givenName.trim().toUpperCase().charAt(0)}
                        {user?.familyName.trim().toUpperCase().charAt(0)}
                      </span>
                    </span>
                  )}

                  <span className="sr-only">Your profile</span>
                  <span aria-hidden="true">{user?.name}</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-yellow-600 px-4 py-4 shadow-sm dark:bg-gray-900 sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-yellow-200 dark:text-white lg:hidden"
          onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
        <NavLink
          to="/profile"
          className={({ isActive }) =>
            classNames(
              isActive
                ? 'text-white dark:bg-gray-800'
                : 'text-gray-400 hover:text-white dark:hover:bg-gray-800',
              'flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6'
            )
          }>
          <span className="sr-only">Your profile</span>
          {user?.picture ? (
            <img
              className="h-8 w-8 rounded-full bg-gray-500"
              src={user.picture}
              alt="Your profile picture"
            />
          ) : (
            <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
              <span className="text-xs font-medium leading-none text-white">
                {user?.givenName.trim().toUpperCase().charAt(0)}
                {user?.familyName.trim().toUpperCase().charAt(0)}
              </span>
            </span>
          )}
        </NavLink>
      </div>

      <main className="h-full max-h-full overflow-auto bg-gray-100 lg:pl-72">
        <Outlet />
      </main>
    </CurrentProfileProvider>
  );
}
