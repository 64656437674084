import { NavLink } from 'react-router-dom';

export default function ConfirmationMessage() {
  return (
    <>
      <p className="mb-3 text-xl font-semibold">Congrats !</p>
      <p className="mb-14 text-sm text-gray-600">
        Your password has been successfully updated and you're now logged in with your new password.
      </p>
      <NavLink
        to="/"
        className="flex w-full justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
        Go to homepage
      </NavLink>
    </>
  );
}
