import { Navigate } from 'react-router-dom';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { ReactElement, createContext, useContext } from 'react';

interface IAuthContext {
  isLogged: () => boolean;
  logout: () => void;
  loginWithBasicCredentials: (
    email: string,
    password: string,
    options: LoginWithBasicCredentialsOptions
  ) => Promise<boolean>;
  logUserWithToken: (
    accessToken: string,
    refreshToken?: string | null,
    options?: LogUserWithTokenOptions
  ) => void;
}

const AuthContext = createContext<IAuthContext | null>(null);

export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (auth === null)
    throw new Error(
      'It looks like you are trying to use useAuth without initialization. Did you forget to add <AuthProvider> in your application tree ?'
    );
  return auth;
};

interface AuthProviderProps {
  children: ReactElement;
}

interface LoginResponse {
  login: {
    accessToken: string;
    refreshToken: string;
  };
}

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

type StoreCredentialsLocallyOptions = {
  remember: boolean;
};

type LoginWithBasicCredentialsOptions = StoreCredentialsLocallyOptions;
type LogUserWithTokenOptions = StoreCredentialsLocallyOptions;

export function AuthProvider({ children }: AuthProviderProps) {
  const apolloClient = useApolloClient();
  const [login] = useMutation<LoginResponse>(LOGIN);

  function storeCredentialsLocally(
    accessToken?: string | null,
    refreshToken?: string | null,
    options?: StoreCredentialsLocallyOptions
  ) {
    // Setup local storage
    if (accessToken) {
      sessionStorage.setItem('accessToken', accessToken);
      if (options?.remember) {
        localStorage.setItem('accessToken', accessToken);
        if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
      }
    }

    // Reset Apollo Client store to avoid board effect (it will refecth cached queries)
    apolloClient.resetStore();
  }
  async function loginWithBasicCredentials(
    email: string,
    password: string,
    options: LoginWithBasicCredentialsOptions
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      login({
        variables: { email, password },
        onCompleted(data) {
          // Setup local storage
          const { accessToken = null, refreshToken = null } = data?.login ?? {};
          storeCredentialsLocally(accessToken, refreshToken, options);
          resolve(true);
        },
        onError(error) {
          reject(error.message);
        }
      });
    });
  }

  function logUserWithToken(
    accessToken: string,
    refreshToken?: string | null,
    options?: LogUserWithTokenOptions
  ) {
    storeCredentialsLocally(accessToken, refreshToken, options);
  }

  function logout() {
    // Clear local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');

    // Clear Apollo Client store
    apolloClient.clearStore();
  }

  function isLogged(): boolean {
    return !!localStorage.getItem('accessToken') || !!sessionStorage.getItem('accessToken');
  }

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        loginWithBasicCredentials,
        logUserWithToken,
        logout
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export function Logout() {
  const auth = useAuth();
  auth.logout();
  return <Navigate to="/login" />;
}
