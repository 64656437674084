import { NavLink } from 'react-router-dom';

export default function RequestPasswordResetEmailSent() {
  return (
    <>
      <p className="mb-3 text-xl font-semibold">Email sent !</p>
      <p className="text-sm text-gray-600">
        Check your inbox, we've sent you an email containing a link to reset your password.
      </p>
    </>
  );
}
