import { CheckCircleIcon } from '@heroicons/react/24/outline';
import BaseNotification, { BaseNotificationProps } from './BaseNotification';

type SuccessNotificationProps = Omit<BaseNotificationProps, 'icon'>;
export default function SuccessNotification({ title, content, onClose }: SuccessNotificationProps) {
  return (
    <BaseNotification
      title={title}
      content={content}
      onClose={onClose}
      icon={<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />}
    />
  );
}
