import * as yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikConfig } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface FormValues {
  email: string;
}

interface RequestPasswordResetResponse {
  requestPasswordReset: {
    success: boolean;
  };
}

const requestPasswordResetSchema = yup.object({
  email: yup.string().email('Please enter a valid email').required('Please enter your password')
});

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      success
    }
  }
`;

export default function RequestPasswordResetForm() {
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [requestPasswordReset] = useMutation<RequestPasswordResetResponse>(REQUEST_PASSWORD_RESET);
  const navigate = useNavigate();

  return (
    <Formik<FormValues>
      initialValues={{ email: '' }}
      validationSchema={requestPasswordResetSchema}
      onSubmit={(values, { setSubmitting }) => {
        requestPasswordReset({
          variables: { email: values.email },
          onCompleted() {
            setSubmitting(false);
            navigate('/reset-password/email-sent');
          },
          onError() {
            setSubmitting(false);
          }
        });
      }}>
      {({ isValid, isSubmitting, status }) => (
        <Form className="space-y-6">
          {status?.formError && (
            <p className="text-center text-sm text-red-600">{status?.formError}</p>
          )}
          <Field name="email">
            {({ field, meta }: FieldProps) => (
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    type="email"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={(event) => {
                      setIsPasswordFocused(false);
                      field.onBlur(event);
                    }}
                    autoComplete="email"
                    required
                    aria-invalid={meta.touched && !!meta.error}
                    className={classNames(
                      meta.touched && meta.error
                        ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                        : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-green-600',
                      'block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                    )}
                  />

                  {!isPasswordFocused && meta.touched && !!meta.error && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {meta.error}
                    </p>
                  )}
                </div>
              </div>
            )}
          </Field>

          <div>
            <button
              type="submit"
              disabled={!isValid || isSubmitting}
              className="flex w-full justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
              Send the link
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
