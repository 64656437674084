import { Fragment, useState } from 'react';
import Loader from '../../designSystem/Loader';

export interface CampaignFormProps {
  onCreateCampaign: (name: string) => void;
  isSubmitting?: boolean;
}

export default function CampaignForm({
  onCreateCampaign,
  isSubmitting = false
}: CampaignFormProps) {
  const [campaignName, setCampaignName] = useState<string>('');

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onCreateCampaign(campaignName);
  };

  return (
    <form className="mt-6 flex" onSubmit={handleSubmit}>
      <label htmlFor="campaignName" className="sr-only">
        Name
      </label>
      <input
        type="text"
        autoComplete="off"
        name="campaignName"
        id="campaignName"
        value={campaignName}
        onChange={(event) => setCampaignName(event.target.value)}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-500 sm:text-sm sm:leading-6"
        placeholder="Enter a campaign name"
      />
      <button
        type="submit"
        disabled={isSubmitting}
        className="ml-4 flex-shrink-0 rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
        {isSubmitting ? <Loader as={Fragment} /> : 'Create a campaign'}
      </button>
    </form>
  );
}
