import { NavLink } from 'react-router-dom';

export default function RegisterEmailSent() {
  return (
    <>
      <p className="mb-3 text-xl font-semibold">Thanks for registering !</p>
      <p className="text-sm text-gray-600">
        You can now check your inboxes, we've sent you an email containing a link to confirm your
        email.
      </p>
    </>
  );
}
