import { gql, useQuery } from '@apollo/client';
import LeadStepper from './components/LeadStepper';
import Loader from '../../../designSystem/Loader';
import { useParams } from 'react-router-dom';
import { EnrichmentSource } from './types';

const GET_CAMPAIGN = gql`
  query GetCampaign($campaignId: ID!) {
    campaign(campaignId: $campaignId) {
      id
      name
      profile {
        id
      }
      leads {
        id
        firstName
        lastName
        email
        companyName
        linkedinUrl
        websiteUrl
        youtubeUrl
      }
      enrichmentSources
      createdAt
    }
  }
`;

export interface Campaign {
  id: string;
  name: string;
  profile: {
    id: string;
  };
  leads?: Lead[];
  enrichmentSources?: EnrichmentSource[];
  createdAt: string;
}

interface GetCampaignResponse {
  campaign: Campaign;
}

export interface Lead {
  id: string;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  linkedinUrl: string;
  websiteUrl: string;
  youtubeUrl: string;
}

export default function LeadsView() {
  const params = useParams();
  const campaignId = params.campaignId as string;
  const { data, loading: isCampaignLoading } = useQuery<GetCampaignResponse>(GET_CAMPAIGN, {
    variables: { campaignId }
  });

  const campaign = data?.campaign ?? null;

  return (
    <div className="mx-auto max-w-4xl px-4 py-10 sm:px-6">
      {isCampaignLoading ? (
        <Loader />
      ) : campaign !== null ? (
        <LeadStepper campaign={campaign} />
      ) : null}
    </div>
  );
}
