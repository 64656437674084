import * as yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikConfig } from 'formik';
import { useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { gql, useMutation } from '@apollo/client';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

interface FormValues {
  password: string;
  confirmationPassword: string;
}

interface ConfirmPasswordResetResponse {
  confirmPasswordReset: {
    accessToken: string;
  };
}

const LIVE_VALIDATIONS = [
  { schema: yup.string().min(8), instruction: 'At least 8 characters' },
  { schema: yup.string().matches(/[0-9]/), instruction: 'At least 1 digit character' },
  { schema: yup.string().matches(/[a-z]/), instruction: 'At least 1 lowercase character' },
  { schema: yup.string().matches(/[A-Z]/), instruction: 'At least 1 uppercase character' },
  {
    schema: yup.string().matches(/[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/),
    instruction: 'At least 1 uppercase character'
  }
];

function getCharacterValidationError(str: string) {
  return `Password must contain at least 1 ${str} character`;
}

const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .required('Please enter your password')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
    .matches(
      /[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/,
      getCharacterValidationError('special')
    ),
  confirmationPassword: yup
    .string()
    .oneOf([yup.ref('password')], "Passwords don't match!")
    .required('Please confirm your password')
});

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const CONFIRM_PASSWORD_RESET = gql`
  mutation ConfirmPasswordReset($resetToken: String!, $newPassword: String!) {
    confirmPasswordReset(resetToken: $resetToken, newPassword: $newPassword) {
      accessToken
    }
  }
`;
export default function ResetPasswordForm() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const navigate = useNavigate();
  const [confirmPasswordReset] = useMutation<ConfirmPasswordResetResponse>(CONFIRM_PASSWORD_RESET);

  if (!token) {
    return <Navigate to="/errors/500" replace={true} />;
  }

  return (
    <Formik<FormValues>
      initialValues={{ password: '', confirmationPassword: '' }}
      validationSchema={resetPasswordSchema}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        confirmPasswordReset({
          variables: {
            newPassword: values.password,
            resetToken: token
          },
          onCompleted(data) {
            const accessToken = data?.confirmPasswordReset?.accessToken ?? null;
            if (accessToken !== null) {
              localStorage.setItem('accessToken', accessToken);
            }
            setSubmitting(false);
            navigate('/reset-password/success');
          },
          onError(error) {
            setSubmitting(false);
            navigate('/reset-password/failed');
          }
        });
      }}>
      {({ isValid, isSubmitting, status }) => (
        <Form className="space-y-6">
          {status?.formError && (
            <p className="text-center text-sm text-red-600">{status?.formError}</p>
          )}
          <Field name="password">
            {({ field, meta }: FieldProps) => (
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    type="password"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={(event) => {
                      setIsPasswordFocused(false);
                      field.onBlur(event);
                    }}
                    autoComplete="new-password"
                    required
                    aria-invalid={meta.touched && !!meta.error}
                    className={classNames(
                      meta.touched && meta.error
                        ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                        : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-green-600',
                      'block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                    )}
                  />
                </div>
                {field.value !== '' &&
                  isPasswordFocused &&
                  LIVE_VALIDATIONS.map((validation, index) => {
                    const isInputValid = validation.schema.isValidSync(field.value);
                    return (
                      <p
                        key={index}
                        className={classNames(
                          'mt-2 flex items-center gap-0.5 text-sm',
                          isInputValid ? 'text-green-600' : 'text-red-600'
                        )}>
                        {isInputValid ? (
                          <CheckIcon className="h-4 w-4" color="currentColor" />
                        ) : (
                          <ExclamationCircleIcon className="h-4 w-4" color="currentColor" />
                        )}
                        {validation.instruction}
                      </p>
                    );
                  })}

                {!isPasswordFocused && meta.touched && !!meta.error && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {meta.error}
                  </p>
                )}
              </div>
            )}
          </Field>

          <Field name="confirmationPassword">
            {({ field, meta }: FieldProps) => (
              <div>
                <label
                  htmlFor="confirmationPassword"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Confirm password
                </label>
                <div className="mt-2">
                  <input
                    id="confirmationPassword"
                    type="password"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    autoComplete="new-password"
                    required
                    aria-invalid={meta.touched && !!meta.error}
                    className={classNames(
                      meta.touched && meta.error
                        ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                        : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                      'block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                    )}
                  />
                </div>
                {meta.touched && !!meta.error && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {meta.error}
                  </p>
                )}
              </div>
            )}
          </Field>

          <div>
            <button
              type="submit"
              disabled={!isValid || isSubmitting}
              className="flex w-full justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
              Reset my password
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
