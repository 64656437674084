import { TableCellsIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import * as yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import LeadsTable, { Column } from '../components/LeadsTable';
import { CompleteStep, CurrentStep, UpcomingStep } from '../components/LeadStepper';
import { Campaign } from '../LeadsView';
import {
  NotificationType,
  useNotificationCenter
} from '../../../../lib/notifications/NotificationProvider';
import CSVParser, {
  InvalidFileContentError,
  InvalidFileFormatError,
  InvalidFileTypeError,
  TooManyRowsError
} from '../../../../lib/csvParser/csvParser';
import Alert, { AlertType } from '../../../../designSystem/Alert';

const leadsCsvSchema = yup.object({
  first_name: yup.string().required('Lead is missing first_name value'),
  last_name: yup.string().required('Lead is missing last_name value'),
  email: yup
    .string()
    .email('Lead email has incorrect format')
    .required('Lead is missing email value'),
  company_name: yup.string().required('Lead is missing company_name value'),
  website_url: yup.string().url('The website_url you provided is invalid'),
  linkedin_url: yup.string().url('The linkedin_url you provided is invalid'),
  youtube_url: yup.string().url('The youtube_url you provided is invalid')
});

const UPDATE_CAMPAIGN_LEADS = gql`
  mutation UpdateCampaignLeads($campaignId: ID!, $leads: [LeadInput!]!) {
    updateCampaignLeads(campaignId: $campaignId, leads: $leads) {
      id
      name
      profile {
        id
      }
      leads {
        id
        firstName
        lastName
        email
        companyName
        linkedinUrl
        websiteUrl
        youtubeUrl
      }
      createdAt
    }
  }
`;

interface UpdateCampaignLeadsResponse {
  updateCampaignLeads: {
    id: string;
    name: string;
    profile: {
      id: string;
    };
    leads: Lead[];
    createdAt: string;
  };
}

interface UpdateCampaignLeadsVariables {
  campaignId: string;
  leads: Omit<Lead, 'id'>[];
}

interface LeadCSVEntry {
  company_name: string;
  email: string;
  first_name: string;
  last_name: string;
  linkedin_url: string;
  website_url: string;
  youtube_url: string;
}

export interface Lead {
  id: string;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  linkedinUrl: string | null;
  websiteUrl: string | null;
  youtubeUrl: string | null;
}

export interface LeadImportCurrentStepProps {
  campaign: Campaign;
  onContinue: () => void;
}

const COLUMNS: Column[] = [
  {
    name: 'First name',
    renderData: (lead: Lead) => lead.firstName
  },
  {
    name: 'Last name',
    renderData: (lead: Lead) => lead.lastName
  },
  {
    name: 'Email',
    renderData: (lead: Lead) => lead.email
  },
  {
    name: 'Company name',
    renderData: (lead: Lead) => lead.companyName
  },
  {
    name: 'Website URL',
    renderData: (lead: Lead) =>
      lead.websiteUrl ? (
        <a className=" hover:text-yellow-500" href={lead.websiteUrl}>
          {lead.websiteUrl}
        </a>
      ) : (
        '-'
      )
  },
  {
    name: 'Youtube URL',
    renderData: (lead: Lead) =>
      lead.youtubeUrl ? (
        <a className=" hover:text-yellow-500" href={lead.youtubeUrl}>
          {lead.youtubeUrl}
        </a>
      ) : (
        '-'
      )
  },
  {
    name: 'Linkedin',
    renderData: (lead: Lead) =>
      lead.linkedinUrl ? (
        <a className=" hover:text-yellow-500" href={lead.linkedinUrl}>
          {lead.linkedinUrl}
        </a>
      ) : (
        '-'
      )
  }
];

export function LeadImportCurrentStep({ campaign, onContinue }: LeadImportCurrentStepProps) {
  const notificationCenter = useNotificationCenter();
  const [updateCampaignLeads] = useMutation<
    UpdateCampaignLeadsResponse,
    UpdateCampaignLeadsVariables
  >(UPDATE_CAMPAIGN_LEADS);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    let totalParsedData: LeadCSVEntry[] = [];
    let isError = false;
    for (const file of acceptedFiles) {
      try {
        const parser = new CSVParser<LeadCSVEntry>(file, { maxRows: 500, schema: leadsCsvSchema });
        const parsedData = await parser.parse();
        totalParsedData.push(...parsedData);
      } catch (err: unknown) {
        let title = null;
        let content = null;
        if (err instanceof InvalidFileTypeError) {
          title = 'Invalid file type';
          content = `The file ${file.name} does not have the right format. Only CSV files are allowed.`;
        } else if (err instanceof InvalidFileContentError) {
          title = 'Invalid file content';
          content = `The file ${file.name} is not a valid CSV file. The following error happened with row n°${err.details?.row} : ${err.details?.detailedMessage}`;
        } else if (err instanceof InvalidFileFormatError) {
          title = 'Invalid file format';
          content = `The file ${file.name} is not a valid CSV file. Please verify the file format.`;
        } else if (err instanceof TooManyRowsError) {
          title = 'Too many rows';
          content = `The file ${file.name} contains ${err.details?.maxEntries} leads. Your file can contain up to 500 leads maximum.`;
        }

        if (title !== null && content !== null) {
          notificationCenter.showNotification({
            title,
            content,
            type: NotificationType.FAIL,
            autoDismissAfter: 10
          });
        }
        isError = true;
      }
    }

    if (acceptedFiles.length > 1 && totalParsedData.length > 500) {
      notificationCenter.showNotification({
        title: 'Too many rows',
        content: `The files you submitted contain ${totalParsedData.length} leads altogether. Please limit the leads number to 500 leads max.`,
        type: NotificationType.FAIL,
        autoDismissAfter: 10
      });
      isError = true;
    }

    if (!isError) {
      const notificationId = notificationCenter.showNotification({
        title: 'Success',
        content: `Please wait a second, we are processing your ${
          acceptedFiles.length > 1 ? 'files' : 'file'
        }`,
        type: NotificationType.SUCCESS
      });

      updateCampaignLeads({
        variables: {
          campaignId: campaign.id,
          leads: totalParsedData.map((entry) => ({
            companyName: entry.company_name,
            email: entry.email,
            firstName: entry.first_name,
            lastName: entry.last_name,
            linkedinUrl: entry.linkedin_url || null,
            websiteUrl: entry.website_url || null,
            youtubeUrl: entry.youtube_url || null
          }))
        },
        onCompleted() {
          notificationCenter.hideNotification(notificationId);
        }
      });
    }
  }, []);

  function onClearLeads() {
    updateCampaignLeads({ variables: { campaignId: campaign.id, leads: [] } });
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const leads = campaign.leads ?? [];
  const isLeadWithoutEnrichmentSource = leads.some(
    (lead) => lead.websiteUrl === null && lead.linkedinUrl === null && lead.youtubeUrl === null
  );
  return (
    <CurrentStep title="Import" description="Import your list of leads from a CSV file">
      {leads.length === 0 && (
        <div
          className="flex justify-center rounded-lg border border-dashed border-gray-900/25 bg-gray-50 px-6 py-10"
          {...getRootProps()}>
          <div className="text-center">
            <TableCellsIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
            <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md font-semibold text-yellow-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-yellow-500 focus-within:ring-offset-2 hover:text-yellow-400">
                <span>Upload a file</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  {...getInputProps()}
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">
              Only CSV files are allowed. Maximum 500 leads.
            </p>
          </div>
        </div>
      )}
      {leads.length > 0 && (
        <div className="sm:px-2">
          {isLeadWithoutEnrichmentSource && (
            <Alert
              className="mb-2"
              type={AlertType.warning}
              title="Attention needed"
              content="One of the leads you provided does not have any enrichment data source. We'll not be
              able to fully personalize this lead email without at least a LinkedIn url, a YouTube
              url or a website url."
            />
          )}
          <LeadsTable leads={leads} columns={COLUMNS} />
          {/* <LeadsList leads={leads} columns={COLUMNS} /> */}
          <div className="mt-4 flex justify-between">
            <button
              type="button"
              onClick={onClearLeads}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
              <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Clear
            </button>
            <button
              type="button"
              onClick={onContinue}
              className="rounded-md bg-yellow-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
              Continue
            </button>
          </div>
        </div>
      )}
    </CurrentStep>
  );
}

export interface LeadImportCompleteStepProps {
  campaign: Campaign;
  onClick: () => unknown;
}
export function LeadImportCompleteStep({ campaign, onClick }: LeadImportCompleteStepProps) {
  const leads = campaign.leads ?? [];
  return (
    <CompleteStep
      title="Import"
      description={
        <span className="mt-1 inline-flex items-center text-sm text-green-500">
          <span>
            {leads.length} {leads.length > 1 ? 'leads' : 'lead'} imported
          </span>
        </span>
      }
      onClick={onClick}
    />
  );
}

export function LeadImportUpcomingStep() {
  return <UpcomingStep title="Import" description="Import your list of leads from a CSV file" />;
}
