import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useCurrentProfile } from './CurrentProfileProvider';
import { useNavigate } from 'react-router-dom';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface Profile {
  id: string;
  name: string;
}

interface ProfileSelectionProps {
  profiles: Profile[];
}

export default function ProfileSelection({ profiles }: ProfileSelectionProps) {
  const { currentProfile, setCurrentProfile } = useCurrentProfile();
  const navigate = useNavigate();

  const onChange = (profile: Profile) => {
    setCurrentProfile(profile);
    navigate('/campaigns');
  };

  return (
    <Listbox value={currentProfile} onChange={onChange}>
      {({ open }) => (
        <div className="relative mt-2">
          <Listbox.Button className="relative w-full cursor-default rounded-md py-1.5 pr-10 text-left text-white shadow-sm focus:outline-none sm:text-sm sm:leading-6">
            <div className="flex items-center gap-x-3">
              <span className="flex h-5 w-5 shrink-0 items-center justify-center rounded-lg border border-yellow-400 bg-yellow-500 text-[0.625rem] font-medium text-white">
                {currentProfile?.name.charAt(0)}
              </span>
              <span className="block truncate">{currentProfile?.name}</span>
            </div>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {profiles.map((profile) => (
                <Listbox.Option
                  key={profile.id}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-yellow-500 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={profile}>
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center">
                        <span
                          className={classNames(
                            active
                              ? 'border-white bg-white text-yellow-500'
                              : 'border-yellow-500 bg-yellow-500 text-white',
                            'flex h-5 w-5 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium'
                          )}>
                          {profile.name.charAt(0)}
                        </span>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'ml-3 block truncate'
                          )}>
                          {profile.name}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-yellow-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
