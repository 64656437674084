import { gql, useQuery } from '@apollo/client';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../lib/auth/AuthProvider';
import countries from '../../lib/i18n/world.json';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const GET_USER = gql`
  query GetUser {
    me {
      id
      name
      email
      emails {
        email
        isVerified
      }
      familyName
      givenName
      locale
      address {
        streetAddress
        city
        country
        postalCode
        region
      }
    }
  }
`;

interface GetUserResponse {
  me: {
    id: string;
    name: string;
    email: string;
    emails: {
      email: string;
      isVerified: boolean;
    }[];
    familyName: string;
    givenName: string;
    locale: string;
    address: {
      streetAddress: string;
      city: string;
      country: string;
      postalCode: string;
      region: string;
    };
  };
}

function getCountryName(countryCode?: string | null) {
  if (countryCode === undefined || countryCode === null) return null;
  return countries.find((country) => countryCode.toLowerCase() === country.alpha2)?.name;
}

export default function ProfileView() {
  const { data } = useQuery<GetUserResponse>(GET_USER);
  const navigate = useNavigate();
  const auth = useAuth();
  const user = data?.me;
  const memoizedCountryName = useMemo(
    () => getCountryName(user?.address.country),
    [user?.address.country]
  );

  function onLogout() {
    auth.logout();
    navigate('/login');
  }

  return (
    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 sm:py-8 lg:px-8 lg:py-10">
      <div className="mx-auto max-w-3xl rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Given name
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{user?.givenName}</div>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Family name
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{user?.familyName}</div>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Email address
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    {user?.emails.map(({ email, isVerified }) => (
                      <div key={email} className="inline-flex items-center space-x-1 text-gray-900">
                        <span>{email}</span>
                        {isVerified && <CheckCircleIcon className="h-4 w-4 text-green-500" />}
                        {user?.email === email && <span className="text-gray-400">(default)</span>}
                      </div>
                    ))}
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Address
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div>
                      <div className="text-gray-900">{user?.address.streetAddress}</div>
                      {(user?.address.postalCode || user?.address.city) && (
                        <div className="text-gray-900">
                          {user?.address.postalCode} {user?.address.city}
                        </div>
                      )}
                      {user?.address.region && (
                        <div className="text-gray-900">{user?.address.region}</div>
                      )}
                      {user?.address.country && (
                        <div className="text-gray-900">{memoizedCountryName}</div>
                      )}
                    </div>
                  </dd>
                </div>
              </dl>
            </div>

            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Language</h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                Choose what language to use throughout your account.
              </p>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Language
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">English</div>
                  </dd>
                </div>
              </dl>
            </div>

            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Sessions</h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                Control how and where you are logged in.
              </p>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <button
                    type="button"
                    className="rounded bg-red-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    onClick={onLogout}>
                    Log out
                  </button>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
