import { useState, createContext, useContext, ReactElement, PropsWithChildren } from 'react';
import { v4 as uuid } from 'uuid';
import SuccessNotification from './components/SuccessNotification';
import NotificationContainer from './components/NotificationContainer';
import FailNotification from './components/FailNotification';

type NotificationProviderProps = PropsWithChildren;

export enum NotificationType {
  SUCCESS = 'success',
  FAIL = 'fail'
}
interface NotificationConfig {
  type: NotificationType;
  title: string;
  content: string;
  autoDismissAfter?: number;
}

interface NotificationExtendedConfig extends NotificationConfig {
  id: ReturnType<typeof uuid>;
  show: boolean;
}

interface NotificationContext {
  notifications: NotificationExtendedConfig[];
  showNotification: (notificationConfig: NotificationConfig) => string;
  hideNotification: (id: ReturnType<typeof uuid>) => void;
}

const NotificationContext = createContext<NotificationContext | null>(null);

// useNotificationCenter will be the hook allowing to dispatch notifications
export const useNotificationCenter = () => {
  const notificationCenter = useContext(NotificationContext);
  return notificationCenter as NotificationContext;
};

// NotificationProvider is the component that will provide context to the NotificationCenter
export function NotificationProvider({ children }: NotificationProviderProps) {
  const [notifications, setNotifications] = useState<NotificationExtendedConfig[]>([]);
  function showNotification(notificationConfig: NotificationConfig) {
    const id = uuid();
    if (notificationConfig.autoDismissAfter) {
      setTimeout(() => hideNotification(id), notificationConfig.autoDismissAfter * 1000);
    }

    setNotifications([...notifications, { ...notificationConfig, id, show: true }]);

    return id;
  }

  function hideNotification(id: ReturnType<typeof uuid>) {
    setNotifications(
      notifications.map((notification) =>
        notification.id === id ? { ...notification, show: false } : notification
      )
    );
    setTimeout(() => {
      setNotifications(notifications.filter((notification) => notification.id !== id));
    }, 5000);
  }

  return (
    <NotificationContext.Provider value={{ showNotification, notifications, hideNotification }}>
      {children}
    </NotificationContext.Provider>
  );
}
// NotificationCenter is the component that will centralize the display of the notifications
export function NotificationCenter() {
  const notificationContext = useContext(NotificationContext);
  if (notificationContext === null) return <></>;

  const { notifications, hideNotification } = notificationContext;
  function displayNotificationContent(notificationConfig: NotificationExtendedConfig) {
    switch (notificationConfig.type) {
      case NotificationType.SUCCESS:
        return (
          <SuccessNotification
            title={notificationConfig.title}
            content={notificationConfig.content}
            onClose={() => hideNotification(notificationConfig.id)}
          />
        );
      case NotificationType.FAIL:
        return (
          <FailNotification
            title={notificationConfig.title}
            content={notificationConfig.content}
            onClose={() => hideNotification(notificationConfig.id)}
          />
        );
    }
  }

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6">
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {notifications.map((notification) => (
          <NotificationContainer key={notification.id} show={notification.show}>
            {displayNotificationContent(notification)}
          </NotificationContainer>
        ))}
      </div>
    </div>
  );
}
