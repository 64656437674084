import { gql, useMutation, useQuery } from '@apollo/client';
import CampaignsTable from './CampaignsTable';
import { Campaign } from './types';
import { useCurrentProfile } from '../../components/CurrentProfileProvider';
import { NavLink, useNavigate } from 'react-router-dom';
import CampaignEmptyState from './CampaignEmptyState';
import CampaignCreationModal from './CampaignCreationModal';
import { useState } from 'react';

interface ListCampaignResponse {
  campaigns: {
    id: string;
    name: string;
    profile: {
      id: string;
    };
    leads: {
      id: string;
    }[];
    createdAt: string;
  }[];
}

interface CreateCampaignResponse {
  createCampaign: {
    id: string;
    name: string;
    profile: {
      id: string;
    };
    createdAt: string;
  };
}

interface DeleteCampaignResponse {
  deleteCampaign: boolean;
}
interface DeleteCampaignVariables {
  campaignId: String;
}

const LIST_CAMPAIGN = gql`
  query ListCampaignsForProfile($profileId: String) {
    campaigns(profileId: $profileId) {
      id
      name
      profile {
        id
      }
      leads {
        id
      }
      createdAt
    }
  }
`;

const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($campaign: CreateCampaignInput!, $profileId: ID!) {
    createCampaign(campaign: $campaign, profileId: $profileId) {
      id
      name
      profile {
        id
      }
      createdAt
    }
  }
`;

const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($campaignId: ID!) {
    deleteCampaign(campaignId: $campaignId)
  }
`;

export default function CampaignsView() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentProfile } = useCurrentProfile();
  const navigate = useNavigate();
  const { data, loading } = useQuery<ListCampaignResponse>(LIST_CAMPAIGN, {
    variables: { profileId: currentProfile?.id }
  });
  const [createCampaign, { loading: isSavingCampaign }] = useMutation<CreateCampaignResponse>(
    CREATE_CAMPAIGN,
    {
      onCompleted(data) {
        if (isModalOpen) setIsModalOpen(false);
        navigate(`${data.createCampaign.id}/leads`);
      },
      update(cache, { data }) {
        const createCampaign = data?.createCampaign;
        cache.modify({
          fields: {
            campaigns(existingCampaigns = []) {
              const newCampaign = cache.writeFragment({
                data: createCampaign,
                fragment: gql`
                  fragment NewCampaign on Campaign {
                    id
                    name
                    profile {
                      id
                    }
                    createdAt
                  }
                `
              });
              return [...existingCampaigns, newCampaign];
            }
          }
        });
      }
    }
  );
  const [deleteCampaign] = useMutation<DeleteCampaignResponse, DeleteCampaignVariables>(
    DELETE_CAMPAIGN,
    {
      update(cache, data, options) {
        const normalizedId = cache.identify({
          id: options.variables?.campaignId,
          __typename: 'Campaign'
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    }
  );

  const onModalToggle = (open: boolean) => {
    setIsModalOpen(open);
  };

  const campaigns = (data?.campaigns || []).map((campaign) => ({
    ...campaign,
    createdAt: new Date(campaign.createdAt)
  }));

  return (
    <div className="overflow-auto px-4 py-6 sm:px-6 sm:py-10 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Campaigns</h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {!loading && campaigns.length !== 0 && (
            <button
              type="button"
              onClick={() => onModalToggle(true)}
              className="block rounded-md bg-yellow-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
              Add campaign
            </button>
          )}
        </div>
      </div>

      {!loading && campaigns.length === 0 && (
        <CampaignEmptyState
          isSubmitting={isSavingCampaign}
          onCreateCampaign={(campaignName) =>
            createCampaign({
              variables: { campaign: { name: campaignName }, profileId: currentProfile?.id }
            })
          }
        />
      )}

      {!loading && campaigns?.length !== 0 && (
        <CampaignsTable
          campaigns={campaigns}
          loading={loading}
          onDeleteCampaign={(campaignId) => deleteCampaign({ variables: { campaignId } })}
        />
      )}

      <CampaignCreationModal
        open={isModalOpen}
        isSubmitting={isSavingCampaign}
        onClose={() => onModalToggle(false)}
        onCreateCampaign={(campaignName) =>
          createCampaign({
            variables: { campaign: { name: campaignName }, profileId: currentProfile?.id }
          })
        }
      />
    </div>
  );
}
