import { gql, useMutation, useQuery } from '@apollo/client';
import Loader from '../../../designSystem/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import SequenceList from './SequenceList';

const GET_SEQUENCES = gql`
  query GetSequences {
    sequences {
      id
      name
      pictureUrl
      description
      numberOfSteps
      steps {
        name
      }
    }
  }
`;

interface GetSequencesResponse {
  sequences: {
    id: string;
    name: string;
    pictureUrl: string;
    description: string;
    numberOfSteps: number;
    steps: {
      name: string;
    }[];
  }[];
}

const SET_CAMPAIGN_SEQUENCE = gql`
  mutation SetCampaignSequence($campaignId: ID!, $sequenceId: ID!) {
    setCampaignSequence(campaignId: $campaignId, sequenceId: $sequenceId) {
      id
      sequence {
        id
      }
    }
  }
`;

interface SetCampaignSequenceVariables {
  campaignId: string;
  sequenceId: string;
}

interface SetCampaignSequenceResponse {
  setCampaignSequence: {
    id: string;
    sequence: {
      id: string;
    };
  };
}

export default function SequencesView() {
  const navigate = useNavigate();
  const params = useParams();
  const campaignId = params.campaignId as string;
  const { data, loading } = useQuery<GetSequencesResponse>(GET_SEQUENCES);
  const [setCampaignSequence] = useMutation<
    SetCampaignSequenceResponse,
    SetCampaignSequenceVariables
  >(SET_CAMPAIGN_SEQUENCE);

  const sequences = data?.sequences ?? [];

  const onSequenceSelection = (sequenceId: string) => {
    setCampaignSequence({
      variables: { campaignId, sequenceId },
      onCompleted: () => {
        navigate(`/campaigns/${campaignId}/parameters`);
      }
    });
  };

  return (
    <div className="mx-auto max-w-5xl px-4 py-10 sm:px-6">
      {loading ? (
        <Loader />
      ) : (
        <SequenceList sequences={sequences} onSequenceSelection={onSequenceSelection} />
      )}
    </div>
  );
}
