import { Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import SuccessNotification from './SuccessNotification';

interface NotificationProps {
  show: boolean;
  children: React.ReactElement<typeof SuccessNotification>;
}

export default function NotificationContainer({ children, show }: NotificationProps) {
  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0">
      <div className="pointer-events-auto z-50 w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">{children}</div>
      </div>
    </Transition>
  );
}
