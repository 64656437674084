import SniprLogo from '../assets/logo_color.png';
import { gql, useMutation } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NotificationType, useNotificationCenter } from '../lib/notifications/NotificationProvider';
import { useEffect } from 'react';
import { request } from 'http';
import { useAuth } from '../lib/auth/AuthProvider';

interface ConfirmEmailResponse {
  confirmEmail: {
    accessToken: string;
  };
}

interface ResendEmailValidation {
  resendEmailValidation: {
    success: boolean;
  };
}

const CONFIRM_EMAIL = gql`
  mutation ConfirmEmail($token: String!) {
    confirmEmail(token: $token) {
      accessToken
    }
  }
`;

const RESEND_EMAIL_VALIDATION = gql`
  mutation ResendEmailValidation($token: String!) {
    resendEmailValidation(token: $token) {
      success
    }
  }
`;

export default function ConfirmEmail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const notificationCenter = useNotificationCenter();
  const [confirmEmail, { error }] = useMutation<ConfirmEmailResponse>(CONFIRM_EMAIL);
  const [resendEmailValidation] = useMutation<ResendEmailValidation>(RESEND_EMAIL_VALIDATION);

  useEffect(() => {
    confirmEmail({
      variables: {
        token
      },
      onCompleted(data) {
        auth.logUserWithToken(data?.confirmEmail.accessToken);
        notificationCenter?.showNotification({
          type: NotificationType.SUCCESS,
          title: 'Email confirmed !',
          content: 'Your email has been successfully confirmed.',
          autoDismissAfter: 5
        });
        navigate('/');
      },
      onError() {}
    });
  }, []);

  function resendEmail() {
    const token = searchParams.get('token');
    if (!token) return;
    try {
      resendEmailValidation({ variables: { token } });
    } catch (err) {}
  }

  const token = searchParams.get('token');
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-10 w-auto" src={SniprLogo} alt="Snipr" />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Email confirmation
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          {!token && <p className="text-sm">Oops... It seems that you arrived here by error.</p>}

          {error && error.message === 'EXPIRED_TOKEN' && (
            <>
              <p className="text-sm">
                Unfortunately, it seems that the link you followed is expired.
                <br />
                If you want to retry validating your email, you can click the link below.
              </p>
              <button
                className="mt-8 flex w-full justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                onClick={resendEmail}>
                Send a new link
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
