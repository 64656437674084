import { CheckIcon } from '@heroicons/react/24/outline';
import LeadsTable, { Column } from '../components/LeadsTable';
import { CompleteStep, CurrentStep, UpcomingStep } from '../components/LeadStepper';
import { Campaign } from '../LeadsView';
import { DEPENDENCIES_BY_SOURCE, enrichmentsEnumToLabel } from '../types';

interface LeadResultsCurrentStepProps {
  campaign: Campaign;
  onContinue: () => void;
}

export interface Lead {
  id: string;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  linkedinUrl: string;
  websiteUrl: string;
  youtubeUrl: string;
}

const COLUMNS: Column[] = [
  {
    name: 'First name',
    renderData: (lead: Lead) => lead.firstName
  },
  {
    name: 'Last name',
    renderData: (lead: Lead) => lead.lastName
  },
  {
    name: 'Email',
    renderData: (lead: Lead) => lead.email
  }
];

export function LeadResultsCurrentStep({ campaign, onContinue }: LeadResultsCurrentStepProps) {
  const leads = campaign.leads ?? [];
  const columns = [...COLUMNS];
  if (campaign.enrichmentSources?.length) {
    columns.push(
      ...campaign.enrichmentSources.map((source) => ({
        name: enrichmentsEnumToLabel[source],
        renderData: (lead: Lead) =>
          DEPENDENCIES_BY_SOURCE[source].every((dependency) => lead[dependency] !== null) ? (
            <CheckIcon className="h-4 w-4 text-green-500" />
          ) : (
            '-'
          )
      }))
    );
  }

  return (
    <CurrentStep title="Results" description="Review the results">
      {leads.length > 0 && (
        <div className="sm:px-2">
          <LeadsTable leads={leads} columns={columns} />
          <div className="mt-4 flex justify-end">
            <button
              type="button"
              onClick={onContinue}
              className="rounded-md bg-yellow-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
              Continue
            </button>
          </div>
        </div>
      )}
    </CurrentStep>
  );
}

export interface LeadResultsCompleteStepProps {
  campaign: Campaign;
  onClick: () => unknown;
}

export function LeadResultsCompleteStep({ campaign, onClick }: LeadResultsCompleteStepProps) {
  return <CompleteStep title="Results" description="Review the results" onClick={onClick} />;
}

export function LeadResultsUpcomingStep() {
  return <UpcomingStep title="Results" description="Review the results" />;
}
