import { XMarkIcon } from '@heroicons/react/24/outline';
import { ReactElement } from 'react';

export interface BaseNotificationProps {
  title: string;
  content: string;
  onClose: () => void;
  icon: ReactElement;
}
export default function BaseNotification({ title, content, onClose, icon }: BaseNotificationProps) {
  return (
    <div className="flex items-start">
      <div className="flex-shrink-0">{icon}</div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-gray-900">{title}</p>
        <p className="mt-1 text-sm text-gray-500">{content}</p>
      </div>
      <div className="ml-4 flex flex-shrink-0">
        <button
          type="button"
          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
          onClick={onClose}>
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
