export enum EnrichmentSource {
  linkedin_user = 'linkedin_user',
  video = 'video',
  company_website = 'company_website'
}

export interface Lead {
  id: string;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  linkedinUrl: string;
  websiteUrl: string;
  youtubeUrl: string;
}

export const DEPENDENCIES_BY_SOURCE: { [key in EnrichmentSource]: (keyof Lead)[] } = {
  [EnrichmentSource.linkedin_user]: ['linkedinUrl'],
  [EnrichmentSource.video]: ['youtubeUrl'],
  [EnrichmentSource.company_website]: ['websiteUrl']
};

export const enrichmentsEnumToLabel: { [key in EnrichmentSource]: string } = {
  [EnrichmentSource.linkedin_user]: 'Linkedin (user profile & activity)',
  [EnrichmentSource.video]: 'Video from Youtube',
  [EnrichmentSource.company_website]: 'Company website'
};
